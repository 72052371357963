import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
	HammerGestureConfig
} from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { AppStoreModule } from './store/app-store.module';
import { LayoutsModule } from './shared/layouts/layouts.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { registerLocaleData, APP_BASE_HREF, PlatformLocation, ɵgetDOM, isPlatformBrowser } from '@angular/common';
import localePtBR from '@angular/common/locales/pt';
import { BrowserStateInterceptor } from './interceptors/browserstate.interceptor';
import { CookieModule } from 'ngx-cookie';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CookieBackendModule } from 'ngx-cookie-backend';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

registerLocaleData(localePtBR);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		AkitaNgRouterStoreModule,
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		CookieModule.forRoot(),
		// CookieBackendModule.forRoot(),
		NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
		SharedModule,
		AppStoreModule,
		LayoutsModule,
		HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxDaterangepickerMd.forRoot()
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
		{ provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
		{
			provide: APP_BASE_HREF,
			useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
			deps: [PlatformLocation]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (platformId: object) => () => {
				if (isPlatformBrowser(platformId)) {
					const dom = ɵgetDOM();
					const styles = Array.prototype.slice.apply(dom.getDefaultDocument().querySelectorAll('style[ng-transition]'));
					styles.forEach(el => {
						// Remove ng-transition attribute to prevent Angular appInitializerFactory
						// to remove server styles before preboot complete
						el.removeAttribute('ng-transition');
					});
					dom.getDefaultDocument().addEventListener('PrebootComplete', () => {
						// After preboot complete, remove the server scripts
						styles.forEach(el => dom.remove(el));
					});
				}
			},
			deps: [PLATFORM_ID],
			multi: true
		},
    provideEnvironmentNgxMask()
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

export function getLocalStorage() {
	return typeof window !== 'undefined' ? window.localStorage : null;
}
