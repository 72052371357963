<div class="container p-3">
  <div class="row">
    <div class="col-md-12 text-right">
      <button type="button" class="btn btn-close-dialog" (click)="onNoClick()">
        <i class="fal fa-times"></i>
      </button>
    </div>
  </div>
  <div class="col-md-auto plan-container p-5">
    <ng-container *ngIf="planLoading; else noLoadingPlan">
      <div class="col">
        <div class="mb-4">
          <ngx-skeleton-loader count="2" appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-md-auto d-flex align-items-center justify-content-center mb-3">
        <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{
            'width.px': 200,
            'height.px': 60
          }">
        </ngx-skeleton-loader>
      </div>
      <div class="col">
        <div class="mb-3">
          <ngx-skeleton-loader count="2" appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
        <div class="mb-3">
          <ngx-skeleton-loader count="2" appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
        <div class="mb-3">
          <ngx-skeleton-loader count="2" appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
        <div class="mb-3">
          <ngx-skeleton-loader count="2" appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>
    <ng-template #noLoadingPlan>
      <div class="row title mb-3">
        <div class="col-12 text-center">
          <h1>{{plan?.name}}</h1>
        </div>
      </div>
      <div class="row price mb-3">
        <div class="col-12 text-center">
          <span class="" style="width: 100%;">R$ <span class="value mx-2">{{getValuePlan(plan?.value)}}</span>
            /mês</span>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 d-flex justify-content-center">
          <button type="button" (click)="routeToOnboarding()" class="btn btn-lg-mkt btn-mkt btn-sign">ASSINAR</button>
        </div>
      </div>
      <div class="row benefits" *ngFor="let item of plan?.items">
        <div class="col-auto d-flex align-items-center">
          <i class="fal fa-check"></i>
        </div>
        <div class="col">
          <span>{{item}}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
