import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SessionService } from '@app-store/session/session.service';

@Injectable({
	providedIn: 'root'
})
export class SessionGuard implements CanLoad {
	constructor(private router: Router, private sessionService: SessionService) {}

	canLoad() {
		return this.validate();
	}

	private validate() {
		return this.sessionService.isAuthenticated().pipe(
			take(1),
			switchMap(isLoggedIn => {
				if (!isLoggedIn) {
					this.router.navigateByUrl('/login');
				}
				return of(isLoggedIn);
			})
		);
	}
}
