import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { TablePatientsComponent } from './table-patients.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TablePatientsComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [TablePatientsComponent]
})
export class TablePatientsModule {}
