import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { PaymentMethods, PaymentSearchDataResponse, PaymentSearchResponse, PaymentStatus } from '@app-model/payment';
import { PaymentService } from '@app-services/payment.service';
import { setCpfMask } from '@app-shared/masks';
import { Utils } from '@app-shared/utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-table-payments-patient',
  templateUrl: './table-payments-patient.component.html',
  styleUrls: ['./table-payments-patient.component.scss']
})
export class TablePaymentsPatientComponent implements OnInit {
	@Input() searchTerm = '';
	@Input() idPatient: string;
	loading = false;
  list: PaymentSearchDataResponse[];
  paymentMethods = PaymentMethods;

  columns = [
		{
			slug: 'doctorName',
			order: true,
			title: 'ESPECIALISTA'
		},
		{
			slug: 'doctorSpecialtyName',
			order: true,
			title: 'ESPECIALIDADE'
		},
		{
			slug: 'value',
			order: true,
			title: 'VALOR'
		},
		{
			slug: 'createdAt',
			order: true,
			title: 'DATA E HORÁRIO'
		},
		{
			slug: 'chargeLastFourDigits',
			order: true,
			title: 'CARTÃO'
		},
		{
			slug: 'paymentMethod',
			order: true,
			title: 'TIPO PGTO'
		},
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

  constructor(
    private readonly toastr: ToastrService,
    private readonly paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.searchPayments();
    this.columns[0].order = !this.idPatient;
  }

  ngOnChanges(changes: SimpleChanges): void {
		const value = changes['searchTerm'];
		if (value?.previousValue && value?.currentValue !== value?.previousValue) {
			this.onSearch();
		}
	}onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.searchPayments();
	}

  private searchPayments() {
    this.initOrStopLoading()
    this.pageControl.count = 0;

    const body = this.setBody();

    this.paymentService.searchPayments(this.pageControl, body)
      .pipe(finalize(() => this.initOrStopLoading()))
      .subscribe(
        res => this.dataHandler(res),
        err => this.errorHandler()
      )
  }

  private setBody() {
    let body = {}

    if(this.idPatient) {
      body = {
        patientId: this.idPatient
      }
    }

    return body;
  }

  private dataHandler(res: PaymentSearchResponse) {
    this.pageControl.count = res.totalElements;
		this.list = res.data;
  }

  private errorHandler() {
		this.list = [];
		this.pageControl.count = 0;
  }

  private initOrStopLoading(): void {
    this.loading = !this.loading;
  }

	private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
		this.searchPayments();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.searchPayments();
	}

  setColorStatusPayment(status: string): string {
    if(status === PaymentStatus.SUCCESS){
      return '#4CAF4B';
    }

    if(status === PaymentStatus.REVERSED){
      return '#FFC727';
    }

    if(status === PaymentStatus.PENDING){
      return '#3A9BD9';
    }

    if(status === PaymentStatus.CANCELED){
      return '#E0DEDC';
    }

    if(status === PaymentStatus.ERROR){
      return '#E54E4E';
    }

    return '#E54E4E';
  }

  cpfMask(value: string): string {
    if(value) return setCpfMask(value);
    return '';
  }

  setDatePayment(value: string): { date: string, time: string, dayOfWeek: string } {
    const val = moment(value);

    const date = val.format('DD/MM/YYYY');
    const time = val.format('HH:mm');
    const dayOfWeek = val.format('dddd');

    return {date, time, dayOfWeek};
  }

  setDayOfWeekToPorguese(value: string): string {
    return Utils.dayOfWeekToPortuguese(value);
  }

}
