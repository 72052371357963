import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateMatcher as MaterialErrorStateMatcher } from '@angular/material/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorStateMatcher } from './config/error-state-matcher';
import { FileUploadModule } from 'ng2-file-upload';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DateFormatAdapter, PICK_FORMATS } from './config/date-format-adapter';
import { PaginatorPtbr } from './config/paginator';
import { ButtonsModule } from './components/buttons/buttons.module';
import { SharedFormsModule } from './forms/shared-forms.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DialogSearchScheduleModule } from './components/dialog-search-schedule/dialog-search-schedule.module';
import { DialogFreeTimeModule } from './components/dialog-free-time/dialog-free-time.module';
import { TablePatientHistoryModule } from './components/table-patient-history/table-patient-history.module';
import { TablePaymentsModule } from './components/table-payments/table-payments.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TableExpertsModule } from './components/table-experts/table-experts.module';
import { DialogNewExpertModule } from './components/dialog-new-expert/dialog-new-expert.module';
import { DialogNewExpertAddressModule } from './components/dialog-new-expert-address/dialog-new-expert-address.module';
import { DialogNewExpertExperienceModule } from './components/dialog-new-expert-experience/dialog-new-expert-experience.module';
import { DialogScheduleFaleComASiModule } from './components/dialog-schedule-fale-com-a-si/dialog-schedule-fale-com-a-si.module';
import { DialogScheduleAgendaDaSiModule } from './components/dialog-schedule-agenda-da-si/dialog-schedule-agenda-da-si.module';
import { DialogPaymentExpertModule } from './components/dialog-payment-expert/dialog-payment-expert.module';
import { DialogBankAccountExpertModule } from './components/dialog-bank-account-expert/dialog-bank-account-expert.module';
import { DialogCropImageModule } from './components/dialog-crop-image/dialog-crop-image.module';
import { DialogWelcomeOnboardingProfile } from './components/dialog-welcome-onboarding-profile/dialog-welcome-onboarding-profile.module';
import { DialogOnboardingValuesModule } from './components/dialog-onboarding-values/dialog-onboarding-values.module';
import { DialogPlanValuesModule } from './components/dialog-plan-values/dialog-plan-values.module';
import { TablePaymentsPatientModule } from './components/table-payments-patient/table-payments-patient.module';
import { TablePatientsModule } from './components/table-patients/table-patients.module';
import { DialogNewPatientModule } from './components/dialog-new-patient/dialog-new-patient.module';
import { DialogNewManagerModule } from './components/dialog-new-manager/dialog-new-manager.module';
import { TableManagerModule } from './components/table-manager/table-manager.module';
import { TablePatientCreditsModule } from './components/table-patient-credits/table-patient-credits.module';
import { DialogPatientAddCreditsModule } from './components/dialog-patient-add-credits/dialog-patient-add-credits.module';
import { TableExpertCommentsModule } from './components/table-expert-comments/table-expert-comments.module';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
	align: 'left',
	allowNegative: true,
	decimal: ',',
	precision: 2,
	prefix: 'R$ ',
	suffix: '',
	thousands: '.'
};

const imports = [
	CommonModule,
	ToastrModule.forRoot(),
	NgbModule,
	SharedComponentsModule,
	SharedDirectivesModule,
	SharedPipesModule,
	RouterModule
];

const exportsComponents = [
	SharedFormsModule,
	CurrencyMaskModule,
	FileUploadModule,
	FormsModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatPaginatorModule,
	MatIconModule,
	MatInputModule,
	MatRadioModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatTabsModule,
	MatTooltipModule,
	MatButtonModule,
  MatButtonToggleModule,
	NgbModule,
	ReactiveFormsModule,
	SharedComponentsModule,
	SharedDirectivesModule,
	SharedPipesModule,
	ButtonsModule,
	MatDialogModule,
	DialogSearchScheduleModule,
	DialogFreeTimeModule,
  DialogNewPatientModule,
  DialogNewManagerModule,
  DialogNewExpertModule,
  DialogNewExpertAddressModule,
  DialogNewExpertExperienceModule,
  DialogScheduleFaleComASiModule,
  DialogScheduleAgendaDaSiModule,
  DialogPaymentExpertModule,
  DialogBankAccountExpertModule,
  DialogCropImageModule,
  DialogWelcomeOnboardingProfile,
  DialogOnboardingValuesModule,
  DialogPatientAddCreditsModule,
	TablePatientHistoryModule,
  TablePatientCreditsModule,
  TablePaymentsModule,
  TablePaymentsPatientModule,
  TableExpertsModule,
  TablePatientsModule,
  TableManagerModule,
	NgxMatSelectSearchModule,
  DialogPlanValuesModule,
  TableExpertCommentsModule
];

const providers = [
	{ provide: MaterialErrorStateMatcher, useValue: new ErrorStateMatcher() },
	{ provide: DateAdapter, useClass: DateFormatAdapter },
	{ provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
	{ provide: MatPaginatorIntl, useClass: PaginatorPtbr },
	{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
];

@NgModule({
	imports,
	exports: exportsComponents,
	providers
})
export class SharedModule {}
