import { validateBr } from 'js-brasil';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';

export class DocumentValidator {
	static cpf(control: AbstractControl): Validators {
		if (control.value) {
      const value = control.value.replace(/\D/g, '');
			if (!validateBr.cpf(value)) {
				return { cpf: true };
			}
		}
		return null;
	}

	static cnpj(control: AbstractControl): Validators {
		const { value } = control;
		if (value) {
			if (!validateBr.cnpj(value)) {
				return {
					cnpj: true
				};
			}
		}
		return null;
	}

	static cpfCnpj(control: AbstractControl): Validators {
		const { value } = control;
		if (value) {
			if (!validateBr.cnpj(value) && !validateBr.cpf(value)) {
				return {
					cpfCnpj: true
				};
			}
		}
		return null;
	}

	static healthPlanValidator(fg: FormGroup): Validators {
		const { value } = fg;
		if (value) {
			if (!value.healthPlanId && !value.particular) {
				return {
					healthPlanChoice: true
				};
			}
		}
		return null;
	}

	static shedulingPhoneEmailValidator(fg: FormGroup): Validators {
		const { value } = fg;
		if (value) {
			if (!value.telephone && !value.email) {
				return {
					phoneEmailChoice: true
				};
			}
		}
		return null;
	}
}
