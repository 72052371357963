export const environment = {
  appName: 'falecomasi',
  home: '/',
	production: true,
	cognito: {
		region: 'us-east-1',
		ClientId: '55r142f35gbqvbiq4i6ku56qrg',
		UserPoolId: 'us-east-1_1A8iJCzKi'
	},
	api: 'https://api-prefeitura.falecomasi.com.br/api/1.0',
	url: 'https://prefeitura.falecomasi.com.br',

	passwordRules: {
		minlength: 8,
		minLowercaseCharacterRule: 1,
		minUppercaseCharacterRule: 1,
		minDigitCharacterRule: 1,
		minSpecialCharacterRule: 1
	},

	iugu_id: '9D81494A225F4215984A5963E784D968',

	title: 'Fale com a Si - Saúde Integral',
	description: `Encontre especialistas próximos de você e agenda sua consulta agora`,
	image: 'https://www.falecomasi.com.br/assets/images/saude-integral-fale-com-a-si.jpg',
	keywords: '',
  urlPagarme: 'https://api.pagar.me/core/v5',
  appIdPagarme: 'pk_gV2N0DQHAKu0QNOn',
  urlMaps: 'https://www.google.com/maps?q='
};
