<div mat-dialog-content class="dialog-free-time-class">
    <div class="row" *ngIf="loading; else complete">
        <div class="col-md-12 mx-auto" style="height: 34px;">
            <div class="loader-bubble loader-bubble-primary d-block"></div>
        </div>
    </div>
	<ng-template #complete>
		<ng-container *ngFor="let d of doctors">
			<div class="info">
				<div class="description">{{ d.name }}</div>
				<div class="icon">
					<a role="button" (click)="selectDoctor(d)"><i class="fal fa-plus"></i></a>
				</div>
			</div>
		</ng-container>
	</ng-template>
</div>
