import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { TableManagerComponent } from './table-manager.component';



@NgModule({
  declarations: [TableManagerComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [TableManagerComponent]
})
export class TableManagerModule { }
