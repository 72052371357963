import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-onboarding-values',
  templateUrl: './dialog-onboarding-values.component.html',
  styleUrls: ['./dialog-onboarding-values.component.scss']
})
export class DialogOnboardingValuesComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<DialogOnboardingValuesComponent>
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
