import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutSessionComponent } from './layout-session/layout-session.component';
import { RouterModule } from '@angular/router';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutRestrictAreaComponent } from './layout-restrict-area/layout-restrict-area.component';
import { LayoutRestrictAreaHeaderComponent } from './layout-restrict-area/header/layout-restrict-area-header.component';
import { LayoutRestrictAreaSidebarComponent } from './layout-restrict-area/sidebar/layout-restrict-area-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@app-shared/directives/shared-directives.module';

@NgModule({
	declarations: [
		LayoutSessionComponent,
		LayoutBlankComponent,
		LayoutRestrictAreaComponent,
		LayoutRestrictAreaHeaderComponent,
		LayoutRestrictAreaSidebarComponent
	],
	imports: [CommonModule, RouterModule, NgbModule, SharedPipesModule, SharedDirectivesModule],
	exports: [LayoutSessionComponent, LayoutRestrictAreaComponent, LayoutBlankComponent]
})
export class LayoutsModule {}
