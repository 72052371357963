import { Component, OnInit, Input } from '@angular/core';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'btn-loading',
	templateUrl: './btn-loading.component.html',
	styleUrls: ['./btn-loading.component.scss']
})
export class BtnLoadingComponent implements OnInit {
	@Input() loading!: boolean | null;
	@Input() btnClass!: string;
	@Input() disabled!: boolean;
	@Input() loadingText = 'Please wait';
	@Input() type: 'button' | 'submit' = 'submit';

	constructor() {}

	ngOnInit() {}
}
