<div class="legend">
  <div class="row">
    <ng-container *ngFor="let i of items">
      <div class="color col-auto mt-2">
        <div *ngIf="i.color" class="icon" [style.background-color]="i.color"></div>
        <div class="description" [style.font-weight]="i.bold ? 700 : 400" [style.color]="i.bold ? '#000' : '#696561'">{{
          i.description }}</div>
      </div>
    </ng-container>
  </div>
</div>
