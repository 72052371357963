<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-close" (click)="close()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row mb-3 p-2">
      <div class="col-lg-6 row">
        <div class="col-lg-12">
          <h1>{{name}}, seja bem-vindo!</h1>
        </div>
        <div class="col-lg-12 description">
          <p>Este é o seu painel do Fale com a Si. Aqui você poderá configurar o seu perfil, administrar a sua agenda e
            acompanhar todos os agendamentos e pagamentos. Tudo em um só lugar!</p>
          <p>Para começarmos, adicione a sua melhor <b>foto de perfil</b>, configure os <b>locais e horários de
              atendimento</b>,
            preencha <b>sua experiência</b> e não esqueça de adicionar seus <b>dados bancários</b>.</p>
          <p>Capriche! O seu perfil estará visível no Fale com a Si <b>somente depois</b> que suas informações estiverem
            completas.</p>
        </div>
        <div class="col-lg-12 btn-submit">
          <button type="button" (click)="close()" class="btn btn-close">Configurar perfil</button>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <img src="./assets/images/si/falecomasi_onboarding-1.png">
      </div>
    </div>
  </div>
</mat-dialog-content>
