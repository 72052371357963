import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { InputWithTagsComponent } from './input-with-tags.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { SharedFormsModule } from '../shared-forms.module';

@NgModule({
  declarations: [
    InputWithTagsComponent
  ],
  exports: [
    InputWithTagsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule
  ]
})
export class InputWithTagsModule {}
