import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PageControl } from '@app-model/page-control';
import { DoctorService } from '@app-services/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-table-experts',
  templateUrl: './table-experts.component.html',
  styleUrls: ['./table-experts.component.scss']
})
export class TableExpertsComponent implements OnInit {
	@Input() searchTerm = '';
	@Input() areaId = '';
	@Input() specialtyId = '';

	public loading = false;
  public list: any[];

	public columns = [
		{
			slug: 'name',
			order: true,
			title: 'NOME'
		},
		{
			slug: 'area',
			order: true,
			title: 'ÁREA'
		},
		{
			slug: 'specialty',
			order: true,
			title: 'ESPECIALIDADE'
		},
		{
			slug: 'document',
			order: true,
			title: 'REGISTRO'
		},
		{
			slug: '',
			order: false,
			title: 'AÇÕES'
		},
	];

	public pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

  customFilter = {
    areaId: null,
    specialtyId: null
  }

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly doctorService: DoctorService
  ) { }

  ngOnInit(): void {
		this.searchExperts();
  }

  routeToProfile(idExpert: string): void {
    this.router.navigate([`/painel/experts/profile/${idExpert}`]);
  }

  routeToSchedule(doctor): void {
    if(!doctor.schedules.length){
      this.toastr.warning('', 'Este especialista ainda não possui um endereço cadastrado. Acesse o perfil e adicione o endereço da clínica para utilizar a agenda.');
      return
    }

    this.router.navigate(['/painel/schedule/'], {state: {doctor}})
  }

  ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, areaId, specialtyId } = changes;
		if (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
			this.onSearch();
		} else if(areaId?.currentValue !== areaId?.previousValue) {
      this.customFilter.areaId = areaId.currentValue || null;
			this.onSearch();
    } else if(specialtyId?.currentValue !== specialtyId?.previousValue) {
      this.customFilter.specialtyId = specialtyId.currentValue || null;
			this.onSearch();
    }
	}

  onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.searchExperts();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.searchExperts();
	}

  private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
		this.searchExperts();
	}

  private searchExperts() {
    this.initOrStopLoading();

    this.doctorService.paginate(this.pageControl, this.customFilter)
      .pipe(finalize(() => this.initOrStopLoading()))
      .subscribe(
        res => this.searchExpertsResponse(res),
        err => this.searchExpertsError(err)
      )
  };

  private searchExpertsResponse(res): void {
    this.list = res.data;
    this.pageControl.count = res.totalElements;
  }

  private searchExpertsError(err): void {
    console.error(err)
  }

  private initOrStopLoading(): void {
    this.loading = !this.loading;
  };

}
