<nav id="sidebar" [ngClass]="{
    active: sidebarService.active,
    'toggle-sidebar': sidebarService.minSidebar,
    'incomplete-profile': incompleteProfile
  }">
  <div [ngStyle]="{'padding': !sidebarService.minSidebar ? '0 15px' : '0' }">
    <ul class="list-unstyled components" *ngIf="!sidebarService.minSidebar">
      <li routerLinkActive="active" [ngClass]="{ 'dropdown-active': item.dropdown }" *ngFor="let item of itemsMenu">
        <a *ngIf="item.type === 'link'" [routerLink]="[item.link]" routerLinkActive="active">
          <i class="fal {{ item.icon }}"></i>
          {{ item.label }}
        </a>
        <ng-container *ngIf="item.type === 'dropdown'">
          <a href="#{{ item.id }}" data-toggle="collapse" (click)="setActiveMenu(item)"
            [attr.aria-expanded]="item.dropdown" class="dropdown-toggle">
            <i class="fal {{ item.icon }}"></i>
            {{ item.label }}
            <i class="fal fa-angle-right icon-dropdown ms-auto"></i>
          </a>
          <ul class="collapse list-unstyled" id="{{ item.id }}" [ngClass]="{ show: item.dropdown }">
            <li *ngFor="let children of item.children">
              <a [routerLink]="[children.link]" routerLinkActive="active">{{ children.label }}</a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
    <ul class="list-unstyled components" *ngIf="sidebarService.minSidebar">
      <li routerLinkActive="active" *ngFor="let item of itemsMenu">
        <a *ngIf="item.type === 'link'" class="justify-content-center" [routerLink]="[item.link]"
          routerLinkActive="active" [title]="item.label">
          <i class="fal {{ item.icon }}"></i>
        </a>
      </li>
    </ul>
    <ul class="list-unstyled components ul-toggle-sidebar" *ngIf="isMobile">
      <li *ngIf="!sidebarService.minSidebar">
        <a (click)="toggleSidebar()" role="button"><i class="fal fa-angle-left"></i> Recolher menu</a>
      </li>
      <li *ngIf="sidebarService.minSidebar">
        <a (click)="toggleSidebar()" class="justify-content-center" role="button"><i class="fal fa-angle-right"></i></a>
      </li>
    </ul>
  </div>
</nav>
