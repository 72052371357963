<div class="container p-3">
  <div class="row">
    <div class="col-md-12 text-right">
      <button type="button" class="btn btn-close-dialog" (click)="onNoClick()">
        <i class="fal fa-times"></i>
      </button>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-md-12 dialog-title">
      <h1>Valores</h1>
    </div>
  </div>
  <div class="row my-3 table-values">
    <div class="col-6 table-values-header">
      <span>Valor da consulta</span>
    </div>
    <div class="col-6 table-values-header">
      <span>Valor Fale com a Si</span>
    </div>
    <div class="col-6">
      <span>R$100,00 - R$200,00</span>
    </div>
    <div class="col-6">
      <span>R$3,00</span>
    </div>
    <div class="col-6">
      <span>R$200,01 - R$500,00</span>
    </div>
    <div class="col-6">
      <span>R$5,00</span>
    </div>
    <div class="col-6">
      <span>R$500,01 - R$800,00</span>
    </div>
    <div class="col-6">
      <span>R$7,00</span>
    </div>
    <div class="col-6">
      <span>≥ R$800,01</span>
    </div>
    <div class="col-6">
      <span>R$10,00</span>
    </div>
  </div>
</div>
