<table class="table table-responsive">
  <thead>
    <tr>
      <th *ngFor="let col of columns" [class.hasorder]="col.order"
        [class.active-order]="pageControl.orderField === col.slug" (click)="onClickOrderBy(col.slug, col.order)">
        {{ col.title }}
        <i class="material-icons" *ngIf="pageControl.orderField === col.slug">
          {{ pageControl.order === 'DESC' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td [attr.colspan]="columns.length">
        <div class="loader-bubble loader-bubble-primary d-block"></div>
      </td>
    </tr>
    <ng-container *ngIf="!loading">
      <tr *ngIf="!list.length">
        <td [attr.colspan]="columns.length" class="text-center">
          <div>Nenhum resultado encontrado</div>
        </td>
      </tr>
      <tr class="hover" *ngFor="let s of list" style="outline: none;" role="button" (click)="openScheduling(s)">
        <td>
          <div class="d-flex">
            <div *ngIf="legend">
              <div *ngIf="screen === 'MKT'" class="flex-grow-0 my-auto table-item-status"
                [ngStyle]="{'background': setColorStatusMkt(s.status)}">
              </div>
              <div *ngIf="screen === 'PAINEL'" class="flex-grow-0 my-auto table-item-status"
                [ngStyle]="{'background': setColorStatusPainel(s.type)}">
              </div>
            </div>
            <div class="flex-grow-1">
              <div>{{ s.name }}</div>
              <div class="small">{{ s.telephone | phonePipe }}{{ (s.telephone && s.email) && ' | ' }}{{ s.email }}</div>
            </div>
          </div>
        </td>
        <td>
          <div>{{ s.doctor }}</div>
          <div class="small">{{ s.specialty }}</div>
        </td>
        <td>
          <div>{{ s.date }}</div>
          <div class="small upper-first-letter">{{ s.dateDayOfWeek }}</div>
        </td>
        <td>
          <div>{{ s.clinicName }}</div>
          <div class="small">{{ s.clinicAddress }}</div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<mat-paginator [length]="pageControl.count" [pageIndex]="pageControl.page" [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="pageControl.limit" (page)="pageEvent($event)"></mat-paginator>
