import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { TableExpertsComponent } from './table-experts.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TableExpertsComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule
  ],
  exports: [TableExpertsComponent]
})
export class TableExpertsModule {}
