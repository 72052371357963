<table class="table table-responsive">
  <thead>
    <th *ngFor="let col of columns" [class.hasorder]="col.order"
      [class.active-order]="pageControl.orderField === col.slug" (click)="onClickOrderBy(col.slug, col.order)">
      {{ col.title }}
      <i class="material-icons" *ngIf="pageControl.orderField === col.slug">
        {{ pageControl.order === 'DESC' ? 'arrow_downward' : 'arrow_upward' }}
      </i>
    </th>
    <th></th>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td [attr.colspan]="columns.length">
        <div class="loader-bubble loader-bubble-primary d-block"></div>
      </td>
    </tr>
    <ng-container *ngIf="!loading">
      <tr *ngIf="!list?.length">
        <td [attr.colspan]="columns.length" class="text-center">
          <div>Nenhum resultado encontrado</div>
        </td>
      </tr>
      <tr class="hover" style="outline: none;" *ngFor="let p of list">
        <td>
          <div class="d-flex">
            <div class="flex-grow-0 my-auto table-item-status"
              [ngStyle]="{'background': setColorStatusPayment(p.status)}"></div>
            <div class="flex-grow-1">
              <div>{{ p.doctorName }}</div>
              <div class="small">{{ p.doctorSpecialtyName }}</div>
            </div>
          </div>
        </td>
        <td>
          <div>{{ p.patientName }}</div>
          <div class="small">{{ cpfMask(p.patientDocument) }}</div>
        </td>
        <td>
          <div>R$ {{ p.value.toFixed(2) }}</div>
        </td>
        <td>
          <div>{{ setDatePayment(p.createdAt).date }}, {{ setDatePayment(p.createdAt).time }}</div>
          <div class="small upper-first-letter">{{ setDayOfWeekToPorguese(setDatePayment(p.createdAt).dayOfWeek) }}
          </div>
        </td>
        <td>
          <div>{{ p.paymentMethod == paymentMethods.CREDIT_CARD ? 'Crédito' : 'Débito' }}</div>
          <div class="small">Cartão</div>
        </td>
        <td>
          <div>Final {{ p.chargeLastFourDigits }}</div>
          <div class="small">{{ p.brand }}</div>
        </td>
        <!-- <td>
          <div>José Osires Baglioli, 433</div>
          <div class="small">Pinheirinho, Curibita/PR</div>
        </td> -->
        <td>
          <div class="link-green" role="button" *ngIf="canReverseMoney(p.status)" (click)="reverseMoney(p)">Cancelar
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<mat-paginator [length]="pageControl.count" [pageIndex]="pageControl.page" [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="pageControl.limit" (page)="pageEvent($event)"></mat-paginator>
