export interface CreateTokenPagarme {
  type: 'card',
  card: CardPagarme
}

export interface CardPagarme {
  number: string;
  holder_name: string;
  exp_month: number;
  exp_year: number;
  cvv: string;
  label?: string;
  brand?: string;
  last_four_digits?: string;
}

export interface CardSi {
  brand: string;
  cardId: string;
  createdAt: string;
  customerId: string;
  expMonth: number;
  expYear: number;
  holderName: string;
  document: string;
  id: string;
  lastFourDigits: string;
  status: string;
  updatedAt: string;
  userId: string;
}

export interface CreateTokenPagarmeResponse extends CreateTokenPagarme {
  id: string;
  created_at: string;
  expires_at: string;
}

export enum PagarmeErrors {
	CARD_NUMBER_INVALID = 'The number field is not a valid card number',
	EXPIRATION_DATE_INVALID = 'The card expiration date is invalid.',
}

export interface OpenSchedulePayment {
  accessToken: string;
  body: OpenSchedulePaymentBody;
}

export interface OpenPaymentAddress {
  street: string;
  cep: string;
  city: string;
  uf: string;
}

export interface OpenSchedulePaymentBody {
  cardToken: string;
  installments: number;
  patientCpf: string;
  type: CardTypes;
  address: OpenPaymentAddress;
}

export interface IPaymentData {
  cardToken: string;
  documentNumber: string;
  documentType: 'cpf' | 'cnpj';
  cep: string;
  address: string;
  city: string;
  uf: string;
}

export interface OpenSchedulePaymentResponse {
  errors: { field: string, message: string }[];
  message: string;
  success: boolean;
}

export enum CardTypes {
  CREDIT_CARD = 'CREDIT_CARD'
}

export interface PaymentSearchResponse {
  actualPage: number;
  numberOfPages: number;
  totalElements: number;
  data: PaymentSearchDataResponse[];
}

export interface PaymentSearchDataResponse {
  afterPeriod: boolean;
  brand: string;
  chargeId: string;
  chargeLastFourDigits: string;
  chargerCode: string;
  clinicId: string;
  createdAt: Date;
  doctorId: string;
  error: string;
  errors: any;
  id: string;
  orderCode: string;
  orderId: string;
  patientDocument: string;
  patientId: string;
  patientName: string;
  patientTelephone: string;
  paymentMethod: PaymentMethods.DEBIT_CARD | PaymentMethods.CREDIT_CARD;
  schedulingId: string;
  success: boolean;
  value: 0;
}

export enum PaymentMethods {
  DEBIT_CARD = "debit_card",
  CREDIT_CARD = "credit_card"
}

export enum PaymentStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  REVERSED = 'REVERSED'
}

export interface PaymentMap {
  cash: boolean;
  credit_card: boolean;
  debit_card: boolean;
  pix: boolean;
}

export interface CustomPayment {
  amount: number;
  doCharge: boolean;
  credits: number;
}

export enum CustomCheckoutPaymentStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  WAITING = 'WAITING'
}

export interface CustomCheckoutPayment {
  amount: number;
  createdAt: string;
  createdBy: string;
  credits: number;
  doctorId: string;
  doctorName: string;
  hasPayment: true
  id: string;
  patientId: string;
  patientName: string;
  status: 'ERROR' | 'SUCCESS' | 'PENDING' | 'EXPIRED' | 'WAITING';
  updatedAt: string;
}

export interface CustomCheckoutPaymentResponse {
  errors: { field: string, message: string }[];
  message: string;
  success: boolean;
  data: CustomCheckoutPayment;
}
