import { AbstractControl, Validators } from '@angular/forms';
import * as cardValidator from 'card-validator';

declare const Iugu: any;

export class CreditCardValidator {
	static validateNumber(control: AbstractControl): Validators {
		if (control.value) {
			const value = control.value.replace(/\D/g, '');
      if(!cardValidator.number(value).isValid){
        return { isCreditCard: true };
      }
		}
		return null;
	}

	static validateExpiration(control: AbstractControl): Validators {
    if(control.value && !cardValidator.expirationDate(control.value).isValid){
      return { dateCreditCard: true };
    }
		return null;
	}

  static validateCVV(control: AbstractControl): Validators {
    if(control.value && !cardValidator.cvv(control.value).isValid){
      return { cvvNumber: true };
    }
		return null;
	}

	static validateCvvAndCreditCard(numberCreditControlName: string, cvvControlName: string) {
		return (form: AbstractControl) => {
			const cardNumber = form.get(numberCreditControlName).value;
			const cvv = form.get(cvvControlName).value;
			if (cardNumber && cvv) {
				const brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber.replace(/\D/g, ''));
				if (!Iugu.utils.validateCVV(cvv, brand)) {
					form.get(cvvControlName).setErrors({
						ccvNumber: true
					});
					return { ccvNumber: true };
				}
			}

      return { cvvNumber: false };
		};
	}
}
