import { CanActivate, Router, CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionService } from '@app-store/session/session.service';
import { take, switchMap, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UserInfoRoles } from '@app-shared/enums/others';

@Injectable({
	providedIn: 'root'
})
export class HasPlanGuard implements CanLoad {
	constructor(private router: Router, private sessionService: SessionService) {}

	canLoad(): Observable<boolean> {
		return this.validate();
	}

	private validate() {
		return this.sessionService.getUser().pipe(
			take(1),
			map(userInfo => {
        if (userInfo && (userInfo.role === UserInfoRoles.ADMIN || userInfo.role === UserInfoRoles.PATIENT)){
          return true;
        }

				if (userInfo && userInfo.hasPlan) {
					return true;
				}

        this.router.navigate([`/onboarding`], { queryParams: { plan: true } })
				return false;
			})
		);
	}
}
