import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { CreateTokenPagarme, CreateTokenPagarmeResponse, CustomCheckoutPaymentResponse, CustomPayment, OpenSchedulePayment, OpenSchedulePaymentBody, OpenSchedulePaymentResponse, PaymentSearchResponse } from '@app-model/payment';
import { Observable } from 'rxjs';
import { PageControl } from '@app-model/page-control';
import { InterceptorSkipHeader } from './auth-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

	createTokenPagarme(payload: CreateTokenPagarme): Observable<CreateTokenPagarmeResponse> {
		return this.http.post<CreateTokenPagarmeResponse>(`${environment.urlPagarme}/tokens?appId=${environment.appIdPagarme}`, payload, {
      headers: new HttpHeaders().set(InterceptorSkipHeader, '')
    });
	}

  openSchedulingPayment(payload: OpenSchedulePayment): Observable<OpenSchedulePaymentResponse> {
    return this.http.post<OpenSchedulePaymentResponse>(`${environment.api}/open/scheduling/payment/${payload.accessToken}`, payload.body);
  }

  searchPayments(paginate: PageControl, body: { clinicId?: string, doctorId?: string, patientId?: string }): Observable<PaymentSearchResponse> {

    const payload = {
      ...body,
      ...paginate,
      pageSize: paginate.limit,
      sortDirection: paginate.order,
      sortField: paginate.orderField
    }

    return this.http.post<PaymentSearchResponse>(`${environment.api}/payment/search`, payload);
  }

  cancelPayment(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api}/payment/cancel/${id}`);
  }

  createCustomPayment(payment: CustomPayment, patientId: string, doctorId: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/doctor/${doctorId}/patient/${patientId}/create-payment-checkout`, payment);
  }

  getCustomPayment(checkoutPaymentId: string): Observable<CustomCheckoutPaymentResponse> {
    return this.http.get<CustomCheckoutPaymentResponse>(`${environment.api}/open/payment-checkout/${checkoutPaymentId}`);
  }

  postCustomPayment(paymentCheckoutId: string, payload): Observable<any> {
    return this.http.post<any>(`${environment.api}/open/payment-checkout/${paymentCheckoutId}/pay`, payload);
  }

  deleteCard(patientId: string, paymentCardId: string): Observable<any>{
    return this.http.delete<any>(`${environment.api}/patient/${patientId}/payment-card/${paymentCardId}/delete`)
  }
}
