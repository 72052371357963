<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12 d-flex align-items-center">
          <h1>Créditos</h1>
          <i class="fal fa-info-circle ms-2" matTooltipHideDelay="1000" [matTooltip]="infoCredits"></i>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput type="number" step="1" formControlName="credits" placeholder="Créditos"
              (keyup)="parseInt($event.target.value)">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-radio-group formControlName="doCharge">
            <mat-radio-button class="me-3" [value]="true">
              Realizar cobrança
              <i class="fal fa-info-circle ms-2" matTooltipHideDelay="1000" [matTooltip]="infoCreditsPayment"></i>
            </mat-radio-button>
            <mat-radio-button [value]="false">Não cobrar</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width" *ngIf="doCharge">
            <input matInput currencyMask formControlName="amount" placeholder="Valor da Cobrança">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form, form.getRawValue())" class="btn mx-3 btn-confirm">Adicionar
          Créditos</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
