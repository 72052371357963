import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControlStatus } from '@app-shared/enums/form';
import { cnpjMask, cpfCnpjMask, cpfMask, phoneMask } from '@app-shared/masks';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Specialty, SpecialtyArea } from '@app-model/open-filter';
import { OpenFilterService } from '@app-services/open-filter.service';
import { MatSelectChange } from '@angular/material/select';
import { DialogCropImageComponent } from '../dialog-crop-image/dialog-crop-image.component';
import { DialogCustomConfigs } from '@app-shared/config/dialog-config';
import { Utils } from '@app-shared/utils';
import { FileUploader } from 'ng2-file-upload';
import { DocumentValidator } from '@app-shared/validators';

@Component({
  selector: 'app-dialog-new-expert',
  templateUrl: './dialog-new-expert.component.html',
  styleUrls: ['./dialog-new-expert.component.scss']
})
export class DialogNewExpertComponent implements OnInit {
	form: FormGroup;

  phoneMask = {
		guide: true,
		mask: phoneMask
  }

  cpfCnpjMask = {
    guide: false,
    mask: cpfCnpjMask
  }

  searchSpecialtyArea = new FormControl();
	filteredSpecialtiesArea: Observable<SpecialtyArea>;
  specialtiesArea: SpecialtyArea[] = [];

	searchSpecialty = new FormControl();
	filteredSpecialties: Observable<Specialty>;
  specialties: Specialty[] = [];

  idExpert: string;

  base64Image: string | ArrayBuffer;
	uploadMessage = '';
	uploadError = false;
	hasFile = false;

  // ufs: string[] = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<DialogNewExpertComponent>,
    private readonly openFilterService: OpenFilterService,
    private readonly dialog: MatDialog,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group(
			{
        photo: [null],
        treatmentPronoun: ['Dr', Validators.required],
        //cpf: [null],
        //cnpj: [null],
				cpfCnpj: [null, DocumentValidator.cpfCnpj],
        name: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				telephone: [null, Validators.required],
        areaId: [null, Validators.required],
        specialtyId: [null, Validators.required],
				documentType: [null, Validators.required],
				document: [null, Validators.required],
			},
		);

    this._getSpecialtiesArea();

    this._fillData(this.data);
  }

  private _getSpecialtiesArea(): void {
    this.openFilterService.getSpecialtiesArea('')
      .subscribe(
        area => {
          this.specialtiesArea = area;
          this.openFilterService.dataSpecialties(area);
          this._initAutocompletesArea();
        }
      )
  }

  areaChange(e: MatSelectChange) {
    const area = this.specialtiesArea.find(area => area.id === e.value);

    if(area) {
      this._fillSpecialties(area.id);
    }
  }

  specialtyChange(e: MatSelectChange) {
    const specialty = this.specialties.find(specialty => specialty.id === e.value);
    if(specialty.documentType){
      this.form.get('documentType').setValue(specialty.documentType);
      return
    }

    this.form.get('documentType').setValue('Documento');
  }

  private _checkIsValidForm(form: FormGroup): boolean {
    let response = true;
    Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
      if(!value.valid){
        response = false;
        form.get(key).markAsDirty({onlySelf: true})
      }
    });
    return response;
  }

  private _fillSpecialties(areaId: string): void {
    this.openFilterService.getSpecialtiesByArea(areaId, '')
      .subscribe(
        specialties => {
          this.specialties = specialties;
          this.openFilterService.dataSpecialties(specialties);
          this._initAutocompletesSpecialty();
        }
      )
  }

  private _initAutocompletesArea() {
		this.filteredSpecialtiesArea = this.searchSpecialtyArea.valueChanges.pipe(
			startWith(''),
			map(value => {
				return this._filter(this.specialtiesArea, value);
			})
		);
	}

  private _initAutocompletesSpecialty() {
		this.filteredSpecialties = this.searchSpecialty.valueChanges.pipe(
			startWith(''),
			map(value => {
				return this._filter(this.specialties, value);
			})
		);
	}

  private _filter(items, value, field = 'name') {
		if (typeof value === 'string') {
			const filterValue = value
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
			return items.filter(option => {
				return (
					filterValue === '' ||
					String(option[field])
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(filterValue)
				);
			});
		}
	}

  private async _fillData(data): Promise<void> {
    if(data){
      const { photo, cpf, cnpj, name, email, telephone, documentType, document, specialtyId, treatmentPronoun, areaId } = data;

      this.form.patchValue({ photo, cpf, cnpj, name, email, telephone, documentType: documentType || 'Documento', document, specialtyId, treatmentPronoun, areaId })

      if(email){
        this.form.get('email').disable({ onlySelf: true });
      }

      if(cpf || cnpj){
        const cpfCnpj = cpf ?? cnpj;
        this.form.get('cpfCnpj').setValue(cpfCnpj);
        this.form.get('cpfCnpj').disable({ onlySelf: true });

      }

      if(data.photo instanceof File){
        await this.onChangeFileInput(data.photo);
      }else{
        this.base64Image = data.photo;
      }

      this._fillSpecialties(areaId);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: FormGroup): void {
    if(Utils.checkIsValidForm(form)) {
      this.dialogRef.close(this.form.getRawValue());

    } else {

      let message = 'Os seguintes campos são obrigatórios: ';
      Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
        if(value.status === FormControlStatus.INVALID || value.status === FormControlStatus.PENDING){
          if(key === 'treatmentPronoun') message += 'Forma de tratamento, '
          if(key === 'name') message += 'Nome, '
          if(key === 'email') message += 'Email, '
          if(key === 'telephone') message += 'Telefone, '
          if(key === 'specialtyName') message += 'Especialidade, '
          if(key === 'document') message += 'Número do conselho, '
        }
      })
      message += 'preencha todos corretamente antes de continuar.'

      this.toastr.warning('', message);
    }
  }

  fileOver(e) {
    const file = e.addedFiles[0];
    if (file.size <= 4194304) {
      this.hasFile = e;
      this.onChangeFileInput(file);
    } else {
      this.toastr.warning('Tamanho máximo do arquivo excedido')
    }
	}

  async onChangeFileInput(event: Event) {
    this.openDialogCropImage(event)
      .subscribe(
        async image => {
          if(image){
            this.base64Image = await Utils.toBase64(image);
            this.form.controls['photo'].setValue(image);
          }
        }
      )
  }

  removePhoto(): void {
    this.base64Image = '';
    this.form.controls['photo'].setValue(null);
  }

  openDialogCropImage(image): Observable<any> {
    const dialogConfig: MatDialogConfig = DialogCustomConfigs.getDialogCustomConfig();
    const data = { data: { image } };

    const dialogRef = this.dialog.open(DialogCropImageComponent, {...dialogConfig, data});

    return dialogRef.afterClosed();
  }

}
