import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { State, City, ViaCep } from '../model/application';
import { APP_BASE_HREF } from '@angular/common';
import { Utils } from '@app-shared/utils';
import { ToastrService } from 'ngx-toastr';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {

  private _baseHref = null;

	constructor(private http: HttpClient, @Inject(APP_BASE_HREF) baseHref: string, private toastr: ToastrService) {
    if(baseHref.endsWith('/')) {
      this._baseHref = baseHref.slice(0, -1);
    } else {
      this._baseHref = baseHref;
    }
  }

	getEstados(): Observable<State[]> {
		return this.http.get(`.${this._baseHref}/assets/json/estados.json`).pipe(
			take(1),
			map((response: State[]) => {
				return response;
			})
		);
	}

	getCidades(sigla: string): Observable<City[]> {
		return this.http.get(`.${this._baseHref}/assets/json/cidades-todas.json`).pipe(
			take(1),
			map((response: City[]) => {
				return response[sigla];
			})
		);
	}

	getCep(cep: string): Observable<ViaCep> {
		return this.http
			.get(`https://viacep.com.br/ws/${cep}/json/`, {
				headers: new HttpHeaders().set(InterceptorSkipHeader, '')
			})
			.pipe(
				take(1),
				map((response: ViaCep) => response)
			);
	}

	getTerms(){
		return this.http.get(`.${this._baseHref}/assets/docs/Termos_de_uso_Si_SAUDE_INTEGRAL_LTDA.pdf`, {
			headers: new HttpHeaders({
				Accept: 'application/pdf'
			}),
			responseType: 'blob'
		}).subscribe(Utils.downloadPDF, _ => {
			this.toastr.error('', 'Falha ao realizar o download do pdf');
		});
	}
}
