<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row">
        <div class="col-md-12 mb-4">
          <h1>Dados de pagamento</h1>
        </div>
        <div class="col-md-12 mb-4">
          <mat-form-field class="example-full-width">
            <mat-label>Número do cartão</mat-label>
            <input formControlName="cardNumber" matInput placeholder="0000 0000 0000 0000" mask="0000 0000 0000 0000">
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field class="example-full-width">
            <mat-label>Data de Validade</mat-label>
            <input formControlName="expirationDate" matInput placeholder="MM/AA" mask="00/00/0000">
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field class="example-full-width">
            <input formControlName="cvv" matInput placeholder="CVV">
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field class="example-full-width">
            <input formControlName="name" matInput placeholder="Nome do titular">
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field class="example-full-width">
            <mat-label>CPF do titular</mat-label>
            <input formControlName="cpf" matInput placeholder="999.999.999-99" mask="000.000.000-00">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onConfirmClick(form)" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>