import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-mat-input-validate-messages',
	styleUrls: ['./mat-input-validate-message.component.scss'],
	template: `
		<div class="mat-form-field-subscript-wrapper">
			<mat-error>
				<span *ngFor="let message of messages">{{ message }}</span>
			</mat-error>
		</div>
	`
})
// tslint:disable-next-line: component-class-suffix
export class MatInputValidateMessages {
	@Input() messages: string[];

	get className() {
		return 'invalid-feedback';
	}

	constructor() {}
}
