<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row">
        <div class="col-md-12 mb-4">
          <h1>Dados bancários</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4">
          <mat-form-field>
            <mat-select formControlName="bank" (selectionChange)="bankChange($event)" placeholder="Banco" matInput>
              <mat-option>
                <ngx-mat-select-search [formControl]="searchBank" placeholderLabel="Pesquisar.."
                  noEntriesFoundLabel="Banco não encontrado">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.value">
                {{ bank.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <div class="radio-container">
            <label>Tipo da conta</label>
            <mat-radio-group formControlName="type" aria-label="Escolha uma opção"
              (change)="setLabelAndMaskDocument($event.value)">
              <mat-radio-button class="radio-item" value="individual">Individual</mat-radio-button>
              <mat-radio-button class="radio-item" value="company">Empresa</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4">
          <mat-form-field>
            <mat-label>Nome do titular</mat-label>
            <input formControlName="name" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field *ngIf="documentType == 'individual'">
            <mat-label>CPF do titular</mat-label>
            <input formControlName="document" matInput placeholder="000.000.000-00" mask="000.000.000-00">
          </mat-form-field>
          <mat-form-field *ngIf="documentType == 'company'">
            <mat-label>CNPJ</mat-label>
            <input formControlName="document" matInput placeholder="00.000.000/0000-00" mask="|00.000.000/0000-00">
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <div class="double-input-bank-account">
            <mat-form-field class="main-input">
              <mat-label>Conta</mat-label>
              <input formControlName="accountNumber" (keypress)="utils.keyPressAlphanumeric($event)" matInput>
            </mat-form-field>
            <mat-form-field class="check-digit">
              <mat-label>Dígito</mat-label>
              <input formControlName="accountCheckDigit" (keypress)="utils.keyPressAlphanumeric($event)" matInput
                maxlength="1">
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="double-input-bank-account">
            <mat-form-field class="main-input">
              <mat-label>Agência</mat-label>
              <input formControlName="branchNumber" (keypress)="utils.keyPressAlphanumeric($event)" matInput>
            </mat-form-field>
            <mat-form-field class="check-digit">
              <mat-label>Dígito</mat-label>
              <input formControlName="branchCheckDigit" (keypress)="utils.keyPressAlphanumeric($event)" matInput
                maxlength="1">
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onConfirmClick(form)" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>