import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';

/**
 * Interceptor responsible for getting the service-side cache using TransferState
 */
@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(private state: TransferState) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const storedResponse: string = this.state.get(makeStateKey(req.url), null);
    if (storedResponse) {
      return of(new HttpResponse({ body: storedResponse, status: 200 }));
    }

    return next.handle(req);
  }
}
