<table class="table table-responsive">
  <thead>
    <th *ngFor="let col of columns" [class.hasorder]="col.order"
      [class.active-order]="pageControl.orderField === col.slug" (click)="onClickOrderBy(col.slug, col.order)">
      {{ col.title }}
      <i class="material-icons" *ngIf="pageControl.orderField === col.slug">
        {{ pageControl.order === 'DESC' ? 'arrow_downward' : 'arrow_upward' }}
      </i>
    </th>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td [attr.colspan]="columns.length">
        <div class="loader-bubble loader-bubble-primary d-block"></div>
      </td>
    </tr>
    <ng-container *ngIf="!loading">
      <tr *ngIf="!list.length">
        <td [attr.colspan]="columns.length" class="text-center">
          <div>Nenhum resultado encontrado</div>
        </td>
      </tr>
      <tr class="hover" style="outline: none;" *ngFor="let p of list">
        <td>
          <div class="d-flex">
            <div class="flex-grow-0 my-auto table-item-status"
              [ngStyle]="{'background': setColorStatusPayment(p.status)}"></div>
            <div class="flex-grow-1">
              <div>{{ p.createdBy }}</div>
            </div>
          </div>
        </td>
        <td>
          <div>{{ p.doctorName }}</div>
        </td>
        <td>
          <div>{{ p?.credits || '--'}}</div>
        </td>
        <td>
          <ng-container *ngIf="p.amount; else dontHasAmount">
            <div>R${{ p.amount.toFixed(2).replace('.',',') }}</div>
          </ng-container>
          <ng-template #dontHasAmount>
            <div>--</div>
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="p.createdAt; else dontHasDate">
            <div>{{ setDatePayment(p.createdAt).date }}, {{ setDatePayment(p.createdAt).time }}</div>
            <div class="small upper-first-letter">{{ setDayOfWeekToPorguese(setDatePayment(p.createdAt).dayOfWeek) }}
            </div>
          </ng-container>
          <ng-template #dontHasDate>
            <div>--</div>
          </ng-template>
        </td>
        <td>
          <button class="btn-action" mat-icon-button [ngClass]="{
              'color-orange': p.status === 'WAITING'
            }" [disabled]="p.status !== 'WAITING'" matTooltip="Clique para copiar o link de pagamento"
            (click)="copyLink(p.id)">
            <i class="fal fa-copy"></i>
          </button>
          <button class="btn-action" mat-icon-button [ngClass]="{
              'color-black-2': p.status === 'ERROR' || p.status === 'EXPIRED'
            }" [disabled]="p.status === 'PENDING' || p.status === 'SUCCESS'" matTooltip="Renovar link de pagamento">
            <i class="fal fa-redo"></i>
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<mat-paginator [length]="pageControl.count" [pageIndex]="pageControl.page" [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="pageControl.limit" (page)="pageEvent($event)"></mat-paginator>