import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';

const components = [ButtonComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule]
})
export class ButtonsModule {}
