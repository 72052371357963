import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Specialty, SpecialtyArea } from '@app-model/open-filter';
import { Patient } from '@app-model/patient';
import { OpenFilterService } from '@app-services/open-filter.service';
import { PatientService } from '@app-services/patient.service';
import { FormControlStatus } from '@app-shared/enums/form';
import { cpfMask, dateMask, phoneMask } from '@app-shared/masks';
import { Utils } from '@app-shared/utils';
import { DocumentValidator } from '@app-shared/validators';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-new-patient',
  templateUrl: './dialog-new-patient.component.html',
  styleUrls: ['./dialog-new-patient.component.scss']
})
export class DialogNewPatientComponent implements OnInit {
  title = 'Cadastrar paciente';
	form: FormGroup;

  phoneMask = {
		guide: true,
		mask: phoneMask
  }

  cpfMask = {
		guide: true,
		mask: cpfMask
  }

  dateMask = {
		guide: true,
		mask: dateMask
  }

  isNewPatient = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<DialogNewPatientComponent>,
    private readonly openFilterService: OpenFilterService,
    private readonly patientService: PatientService,
    private readonly dialog: MatDialog,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
    ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: [null, [Validators.required]],
      birthDate: [null, [Validators.required]],
      cpf: [null, [DocumentValidator.cpf]],
      telephone: [null, [Validators.required]],
      email: [null, [Validators.required]],
      credits: [0, [Validators.required, Validators.min(0)]],
    })

    this.isNewPatient = this.data.isNewPatient;

    if(this.data && !this.data.isNewPatient){
      this._fillData(this.data);
    }

    if(!this.isNewPatient) {
      this.title = "Editar paciente";
    }
  }

  private _fillData(patient: Patient): void {
    this.form.patchValue({
      ...patient,
      birthDate: moment(patient.birthDate).format('DD/MM/YYYY')
    });

    this.form.get('email').disable();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: FormGroup, value): void {
    if(Utils.checkIsValidForm(form)) {

      this.dialogRef.close({...value, birthDate: Utils.dateBrToIso(value.birthDate)});

    } else {

      let message = 'Os seguintes campos são obrigatórios: ';
      Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
        if(value.status === FormControlStatus.INVALID || value.status === FormControlStatus.PENDING){
          if(key === 'name') message += 'Nome, ';
          if(key === 'email') message += 'Email, ';
          if(key === 'telephone') message += 'Telefone, ';
          if(key === 'birthDate') message += 'Data de nascimento, ';
          if(key === 'credits') message += 'Número de créditos, ';
        }
      })
      message += 'preencha todos corretamente antes de continuar.';

      this.toastr.warning('', message);
    }
  }

  getPatientByEmail(email): void {
    if(this.isNewPatient){
      this.patientService.getByEmail(email)
        .pipe()
        .subscribe(res => {
          if(res.success){
            this.form.patchValue({
              ...res.data,
              birthDate: moment(res.data.birthDate).format('DD/MM/YYYY')
            });
          } else {
            this._clearForm();
          }
        })
    }
  }

  private _clearForm(): void {
    this.form.patchValue({
      id: null,
      name: null,
      birthDate: null,
      cpf: null,
      telephone: null,
      credits: 0,
    })
  }

}
