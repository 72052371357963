<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row mb-3 p-2">
      <div class="col-md-12 mb-4">
        <h1>Adicionar nova Experiência</h1>
      </div>
      <div class="col-md-12 mb-4">
        <mat-form-field class="example-full-width">
          <textarea rows="3" matInput [(ngModel)]="about" placeholder="Sobre mim"></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12 mb-4">
        <mat-form-field class="example-full-width">
          <textarea rows="3" matInput [(ngModel)]="experiences" label="Experiência em"
            placeholder="Adicionar experiência"></textarea>
        </mat-form-field>
        <!-- <app-input-with-tags labelIcon="fa-stars" label="Experiência em" placeholder="Adicionar experiência">
        </app-input-with-tags> -->
      </div>
      <div class="col-md-12 mb-4">
        <mat-form-field class="example-full-width">
          <textarea rows="3" matInput [(ngModel)]="graduations" label="Formação"
            placeholder="Adicionar formação"></textarea>
        </mat-form-field>
        <!-- <app-input-with-tags labelIcon="fa-graduation-cap" label="Formação" placeholder="Adicionar formação">
        </app-input-with-tags> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" class="btn mx-3 btn-cancel" (click)="onNoClick()">Cancelar</button>
        <button type="button" class="btn mx-3 btn-confirm" (click)="onConfirmClick()">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
