import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { CreditStatus } from '@app-model/patient';
import { PatientService } from '@app-services/patient.service';
import { Utils } from '@app-shared/utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-patient-credits',
  templateUrl: './table-patient-credits.component.html',
  styleUrls: ['./table-patient-credits.component.scss']
})
export class TablePatientCreditsComponent implements OnInit {
	@Input() searchTerm = '';
	@Input() idPatient = '';
	@Input() loading = false;

	list: any[] = [];

	columns = [
		{
			slug: 'name',
			order: true,
			title: 'RESPONSÁVEL'
		},
		{
			slug: 'doctorName',
			order: true,
			title: 'ESPECIALISTA'
		},
		{
			slug: 'credits',
			order: true,
			title: 'CRÉDITOS'
		},
		{
			slug: 'value',
			order: true,
			title: 'VALOR COBRADO'
		},
		{
			slug: 'createdAt',
			order: true,
			title: 'DATA E HORA'
		},
		{
			slug: '',
			order: false,
			title: 'AÇÕES'
		}
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'start',
		order: 'DESC',
		count: 0
	};

  constructor(
    private readonly router: Router,
    private readonly clipboard: Clipboard,
    private readonly toastr: ToastrService,
    private readonly patientService: PatientService,
  ) { }

	ngOnInit(): void {
		this.getResults();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, scheduleType, loading } = changes;
		if (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
			this.onSearch();
		} else if (scheduleType?.currentValue !== scheduleType?.previousValue){
			this.onSearch();
    } else if (loading?.currentValue !== loading?.previousValue) {
			this.onSearch();
    }
	}

	onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.getResults();
	}

	private getResults() {
    this.loading = true;

		this.pageControl.count = 0;
    this.patientService.getPaymentCheckouts(this.pageControl, this.idPatient)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        res => this.dataHandler(res),
        err => console.error(err)
      )
	}

  private dataHandler(response) {
		this.pageControl.count = response.totalElements;
		this.list = response.data;
	}

	private errorHandler() {
		this.list = [];
		this.pageControl.count = 0;
	}

	private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.param = 'description';
		this.pageControl.page = 0;

    if(!this.loading)
		  this.getResults();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.getResults();
	}



  setColorStatusPayment(status: string): string {
    if(status === CreditStatus.SUCCESS){
      return '#56AEA3';
    }

    if(status === CreditStatus.PENDING){
      return '#3A9BD9';
    }

    if(status === CreditStatus.WAITING){
      return '#F49928';
    }

    if(status === CreditStatus.EXPIRED){
      return '#444341';
    }

    if(status === CreditStatus.ERROR){
      return '#E54E4E';
    }



    return '#E54E4E';
  }

  setDatePayment(value: string): { date: string, time: string, dayOfWeek: string } {
    const val = moment(value);

    const date = val.format('DD/MM/YYYY');
    const time = val.format('HH:mm');
    const dayOfWeek = val.format('dddd');

    return {date, time, dayOfWeek};
  }

  setDayOfWeekToPorguese(value: string): string {
    return Utils.dayOfWeekToPortuguese(value);
  }

  copyLink(id: string): void {
    const baseUrl = window.location.origin;

    const isHomolog = baseUrl.includes('actionlabs');
    if(isHomolog) {
      this.clipboard.copy(`${window.location.origin}/app/pagamento/${id}`);
    } else {
      this.clipboard.copy(`${window.location.origin}/pagamento/${id}`);
    }

    this.toastr.success('Link de pagamento copiado');
  }

}
