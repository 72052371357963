import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { SidebarService } from '@app-services/sidebar.service';
import { SessionQuery } from '@app-store/session/session.query';
import { UserInfoRoles } from '@app-shared/enums/others';
import { DoctorService } from '@app-services/doctor.service';
import { SessionService } from '@app-store/session/session.service';

@Component({
	selector: 'app-layout-restrict-area',
	templateUrl: './layout-restrict-area.component.html',
	styleUrls: ['./layout-restrict-area.component.scss']
})
export class LayoutRestrictAreaComponent implements OnInit {
	moduleLoading: boolean;

  incompleteProfile = false;

	constructor(
    private sessionQuery: SessionQuery,
    public sidebarService: SidebarService,
    private router: Router,
		private doctorService: DoctorService,
    private sessionService: SessionService
  ) {}

	ngOnInit() {
		this.router.events.subscribe(event => {
			if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
				this.moduleLoading = true;
			}
			if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
				this.moduleLoading = false;
			}
		});

    this._isIncompleteDoctor();
	}

  private _isIncompleteDoctor(): void {
    this.sessionQuery.userInfo$.subscribe(info => {
      if(info && info.role === UserInfoRoles.DOCTOR) {
        this.sessionQuery.doctor$.subscribe(doctor => {
          if(doctor){
            this.incompleteProfile = doctor.profileStatus === 'INACTIVE';
          }
        })
      }
    })
  }
}
