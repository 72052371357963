<div class="ng2FileDrop" ng2FileDrop [uploader]="uploader">
	<div class="row">
		<div class="col-12">
			<em>Arraste e solte o arquivo aqui</em>
		</div>
		<div class="col-12 d-flex justify-content-center">
			<button class="btn btn-outline-primary">
				<span>SELECIONAR ARQUIVO</span>
				<input type="file" ng2FileSelect [uploader]="uploader" [multiple]="multiple" />
			</button>
		</div>
		<div class="col-12 mt-3">
			<small class="max-filesize">Tamanho máximo: 4MB</small>
		</div>
	</div>
</div>
