import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableExpertCommentsComponent } from './table-expert-comments.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [TableExpertCommentsComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  exports: [TableExpertCommentsComponent]
})
export class TableExpertCommentsModule { }
