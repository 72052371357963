<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>Adicionar novo Endereço</h1>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="name" placeholder="Nome do local">
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="cep" placeholder="CEP" (ngModelChange)="getAddressByCep($event)"
              mask="00.000-00">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="address" placeholder="Endereço">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="number" placeholder="Número">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="complement" placeholder="Complemento">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="neighborhood" placeholder="Bairro">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="city" placeholder="Cidade" (ngModelChange)="getCities($event)"
              autocomplete="off" [matAutocomplete]="auto">
          </mat-form-field>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="citySelected($event.option)">
            <mat-option *ngFor="let option of filteredCities" [value]="option.name">
              {{option.name}}/{{option.uf}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="uf" placeholder="Estado" readonly>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="telephone" placeholder="Telefone" mask="(00) 0000-0000||(00) 00000-0000">
          </mat-form-field>
        </div>
        <!-- <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="clinicType" placeholder="Tipo de Clínica">
          </mat-form-field>
        </div> -->
      </div>
    </form>
    <form [formGroup]="scheduleTypeMap">
      <mat-label>Tipo de atendimento</mat-label>
      <div class="row">
        <div class="col-md-6 mt-3">
          <mat-slide-toggle formControlName="commum">
            Presencial
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="online" class="ms-3">
            Online
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="homecare" class="ms-3">
            Domiciliar
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form)" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
