import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpjPipe'
})
export class CnpjPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let cnpj = value ? value.replace(/\D/g, '') : '';

    if (cnpj && cnpj.length === 14) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else if (!cnpj) {
      return '---';
    } else {
      return value;
    }
  }

}
