<div class="rating">
	<span
		*ngFor="let i of numbers"
		(click)="onRateProvider(i)"
		[ngStyle]="{ 'font-size': fontsize, color: color }"
		class="material-icons size"
	>
		{{ rating < i ? 'star_outline' : 'star' }}
	</span>
</div>
