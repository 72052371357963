import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DialogPatientAddCreditsComponent } from './dialog-patient-add-credits.component';
import { MatRadioModule } from '@angular/material/radio';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [DialogPatientAddCreditsComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    CurrencyMaskModule,
    MatTooltipModule
  ]
})
export class DialogPatientAddCreditsModule { }
