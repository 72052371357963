import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-input-with-tags',
  templateUrl: './input-with-tags.component.html',
  styleUrls: ['./input-with-tags.component.scss']
})
export class InputWithTagsComponent {
  @Input() label: string = '';
  @Input() labelIcon: string = '';
  @Input() placeholder: string = '';

  @Input() autocompleteItemName: string = '';
  @Input() autocomplete = [];
	@Input() filteredAutocomplete: Observable<any>;
  @Input() searchAutocomplete: FormControl;

  @Input() tags: string[] = [];

  @Output() onSetTag: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveTag: EventEmitter<any> = new EventEmitter();

  tag: string = "";

  constructor() { }

  addTag(): void {
    if(this.tag){
      this.emitTag(this.autocomplete.find(item => item.id === this.tag));
    }
  }

  removeTag(index: number): void {
    const tag = this.tags[index];
    this.onRemoveTag.emit(tag);
  }

  change(e: MatSelectChange) {
    this.emitTag(this.autocomplete.find(item => item.id === e.value));
    e.source.ngControl.control.setValue(null);
  }

  emitTag(tag): void {
    this.onSetTag.emit(tag);
  }

}
