import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HealthPlan, Specialty } from '@app-model/open-filter';
import { OpenFilterService } from '@app-services/open-filter.service';
import { PlanService } from '@app-services/plan.service';
import { SpecialtyService } from '@app-services/specialty.service';
import { FormControlStatus } from '@app-shared/enums/form';
import { Utils } from '@app-shared/utils';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PaymentMap } from '../../../model/payment';

@Component({
  selector: 'app-dialog-schedule-fale-com-a-si',
  templateUrl: './dialog-schedule-fale-com-a-si.component.html',
  styleUrls: ['./dialog-schedule-fale-com-a-si.component.scss']
})
export class DialogScheduleFaleComASiComponent implements OnInit {
	form: FormGroup;
  paymentMap: FormGroup;
  attendanceMap: FormGroup;

	searchSpecialty = new FormControl();
	filteredSpecialties: Observable<Specialty>;
  specialties: Specialty[] = [];

	searchPlan = new FormControl();
	filteredPlans: Observable<HealthPlan>;
  healthPlans: HealthPlan[] = [];

  infoPaymentType = 'Aqui, você deve selecionar se o pagamento será feito no checkout ou pessoalmente após a consulta. Atenção: a taxa paga em caso de falta do paciente só é válida se o pagamento da consulta tiver sido feito pelo Fale com a Si.';

  constructor(
    private readonly fb: FormBuilder,
    private readonly openFilterService: OpenFilterService,
    private readonly specialtyService: SpecialtyService,
    private readonly toastr: ToastrService,
    private readonly dialogRef: MatDialogRef<DialogScheduleFaleComASiComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.attendanceMap = this.fb.group({});

    this.paymentMap = this.fb.group({
      credit_card: [false, Validators.required],
      debit_card: [false, Validators.required],
      pix: [false, Validators.required],
      cash: [false, Validators.required],
    });

    this.form = this.fb.group({
      healthPlans: [[]],
      ages: [''],
      value: [null],
      billingMethod: ['si', [Validators.required]],
      paymentMap: this.paymentMap,
      attendanceMap: this.attendanceMap,
      specialties: [[]]
    });

    this._fillData(this.data);
    this._fillAutocompletes();
  }

  private _setAttendanceMap(attendanceMap): void {
    Object.entries(attendanceMap).forEach((([key, values]: [key: string, values: boolean]) => {
      this.attendanceMap.addControl(key, new FormControl(values));
    }));
  }

  private _fillAutocompletes(): void {
    forkJoin([
      this.openFilterService.getSpecialties(''),
      this.openFilterService.getHealthPlans('')
    ]).subscribe(
			([specialties, plans]) => {
				this.specialties = specialties;
				this.healthPlans = plans;

				this.openFilterService.dataPlans(plans);
				this.openFilterService.dataSpecialties(specialties);

				this._initAutocompletes();
			}
		);
  }

  attendanceToPorguese(value: string): string {
    return Utils.attendanceTypeToPortuguese(value.toUpperCase());
  }

  private _initAutocompletes() {
		this.filteredSpecialties = this.searchSpecialty.valueChanges.pipe(
			startWith(''),
			map(value => {
				return this._filter(this.specialties, value);
			})
		);
		this.filteredPlans = this.searchPlan.valueChanges.pipe(
			startWith(''),
			map(value => {
				return this._filter(this.healthPlans, value);
			})
		);
	}

  private _filter(items, value, field = 'name') {
		if (typeof value === 'string') {
			const filterValue = value
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
			return items.filter(option => {
				return (
					filterValue === '' ||
					String(option[field])
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(filterValue)
				);
			});
		}
	}

  private _fillData(data): void {
    const { healthPlans, specialties, ages, value, paymentMap, attendanceMap, billingMethod } = data;
    this.form.patchValue({ ages, value, paymentMap, billingMethod })

    if(healthPlans){
      this.form.patchValue({healthPlans})
    }else{
      this.form.patchValue({healthPlans: []})
    }

    if(specialties){
      this.form.patchValue({specialties})
    }else{
      this.form.patchValue({specialties: []})
    }

    this._setAttendanceMap(attendanceMap);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit({status, value}): void {
    if(status === FormControlStatus.VALID){
      this.dialogRef.close(value);
    }
  }

  sendTags(param: string): string[] {
    return this.form.controls[param].value.map(item => item.name);
  }

  setPlans(plan): void {
    if(plan){
      const plans = this.form.controls['healthPlans'].value;

      const exist = plans.find(item => item.id === plan.id);
      if(!exist){
        plans.push(plan);
        this.form.controls['healthPlans'].setValue(plans);
      }else{
        this.toastr.warning('', 'Convênio já inserido')
      }
    }
  }

  removePlans(plan): void {
    if(plan) {
      const healthPlans = this.form.get('healthPlans').value;
      const index = healthPlans.findIndex(item => item.name === plan);

      healthPlans.splice(index, 1);

      this.form.get('healthPlans').setValue(healthPlans);
    }
  }

  setSpecialty(specialty): void {
    if(specialty){
      const specialties = this.form.controls['specialties'].value;

      const exist = specialties.find(item => item.id === specialty.id);
      if(!exist){
        specialties.push(specialty);
        this.form.controls['specialties'].setValue(specialties);
      }else{
        this.toastr.warning('', 'Especialidade já inserida')
      }
    }
  }

  removeSpecialty(specialty): void {
    const specialties = this.form.get('specialties').value;
    const index = specialties.findIndex(item => item.name === specialty);

    specialties.splice(index, 1);

    this.form.get('specialties').setValue(specialties);
  }

}
