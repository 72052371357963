import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ExperienceData {
  about: string;
  experiences: string;
  graduations: string;
}
@Component({
  selector: 'app-dialog-new-expert-experience',
  templateUrl: './dialog-new-expert-experience.component.html',
  styleUrls: ['./dialog-new-expert-experience.component.scss']
})
export class DialogNewExpertExperienceComponent implements OnInit {

  about = '';
  experiences = '';
  graduations = '';

  constructor(
    private readonly dialogRef: MatDialogRef<DialogNewExpertExperienceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.fillFields(this.data);
  }

  fillFields({about, experiences, graduations}: ExperienceData) {
    this.about = about;
    this.experiences = experiences;
    this.graduations = graduations;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.dialogRef.close({
      about: this.about,
      experiences: this.experiences,
      graduations: this.graduations
    });
  }

}
