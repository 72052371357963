import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-welcome-onboarding-profile',
  templateUrl: './dialog-welcome-onboarding-profile.component.html',
  styleUrls: ['./dialog-welcome-onboarding-profile.component.scss']
})
export class DialogWelcomeOnboardingProfileComponent implements OnInit {

  name: string = '';

  constructor(
    private readonly dialogRef: MatDialogRef<DialogWelcomeOnboardingProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.name = this.data.name;
  }

  close(): void {
    this.dialogRef.close();
  }

}
