import { Component, OnInit, AfterContentInit } from '@angular/core';
import { SessionService } from './store/session/session.service';
import { filter, map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserInfoRoles } from '@app-shared/enums/others';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
	constructor(
		private sessionService: SessionService,
		private readonly metaService: Meta,
		private readonly titleService: Title,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {}

	ngOnInit(): void {
		if (!environment.production) {
			this.metaService.addTag({
				name: 'robots',
				content: 'noindex'
			});
		}

		this.titleService.setTitle(environment.title);

		this.metaService.addTags([
			{
				name: 'title',
				content: environment.title
			},
			{
				name: 'description',
				content: environment.description
			},
			{
				name: 'image',
				content: environment.image
			},
			{
				name: 'keywords',
				content: environment.keywords
			},
			{
				property: 'og:url',
				content: environment.url
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:title',
				content: environment.title
			},
			{
				property: 'og:description',
				content: environment.description
			},
			{
				property: 'og:image',
				content: environment.image
			}
		]);


		this.sessionService
			.isAuthenticated()
			.pipe(filter(isAuthenticated => isAuthenticated))
			.subscribe(() => {
				this.sessionService.getUser().subscribe();
			});


		this.router.events
		.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => {
				let child = this.route.firstChild;
				while (child.firstChild) {
					child = child.firstChild;
				}
				if (child.snapshot.data['pageTitle']) {
					return child.snapshot.data['pageTitle'];
				}
				return null;
			})
		)
		.subscribe((ttl: string) => {
			if (ttl) {
				this.titleService.setTitle(`${ttl} - ${environment.title}`);
				return;
			}
			this.titleService.setTitle(environment.title);
		});
	}
}
