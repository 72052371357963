import Cookies from 'universal-cookie';

export class CookieStorage implements Storage {
	cookies: Cookies;
	store: any;
	isBrowser: boolean;

	constructor(context, isBrowser) {
		this.isBrowser = isBrowser;
		this.store = this.isBrowser ? window.localStorage : {};
		this.cookies = context.req ? new Cookies(context.req.headers.cookie) : new Cookies();
		Object.assign(this.store, this.cookies.getAll());
	}

	clear(): void {
		Array.from(new Array(this.length))
			.map((_, i) => {
				return this.key(i);
			})
			.forEach(key => {
				return this.removeItem(key);
			});
	}
	getItem(key: string): string {
		key = encodeURIComponent(key);
		return Object.prototype.hasOwnProperty.call(this.store, key) ? this.store[key] : null;
	}
	key(index: number): string {
		return Object.keys(this.store)[index];
	}
	removeItem(key: string): void {
		key = encodeURIComponent(key);
		delete this.store[key];
		this.cookies.remove(key, {
			path: '/',
			sameSite: true,
			secure: true
		});
	}
	setItem(key: string, value: string): void {
		key = encodeURIComponent(key);
		this.cookies.set(key, value, {
			path: '/',
			sameSite: true,
			secure: true
		});
		Object.assign(this.store, this.cookies.getAll());
	}

	get length(): number {
		return Object.entries(this.store).length;
	}
}
