import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserPlan } from '@app-model/plan';
import { OpenFilterService } from '@app-services/open-filter.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-plan-values',
  templateUrl: './dialog-plan-values.component.html',
  styleUrls: ['./dialog-plan-values.component.scss']
})
export class DialogPlanValuesComponent implements OnInit {

  planLoading = false;
  plan: UserPlan;

  constructor(
    private readonly openService: OpenFilterService,
		private readonly router: Router,
    private readonly dialogRef: MatDialogRef<DialogPlanValuesComponent>
  ) { }

  ngOnInit(): void {
    this._getPlan();
  }

  private _initOrStopLoadingPlan(): void {
    this.planLoading = !this.planLoading;
  }

  private _getPlan(): void {
    this._initOrStopLoadingPlan();
    this.openService.getUserPlans()
      .pipe(finalize(() => this._initOrStopLoadingPlan()))
      .subscribe(res => {
        this.plan = res[0];
      })
  }

  getValuePlan(value): string {
    return value ? (value).toFixed(2).replace('.', ',') : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  routeToOnboarding() {
		this.router.navigate(['onboarding']);
    this.dialogRef.close();
  }

}
