import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PageControl } from '@app-model/page-control';
import { PaymentSearchDataResponse, PaymentSearchResponse, PaymentMethods, PaymentStatus } from '@app-model/payment';
import { PaymentService } from '@app-services/payment.service';
import { setCpfMask } from '@app-shared/masks';
import { Utils } from '@app-shared/utils';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-table-payments',
  templateUrl: './table-payments.component.html',
  styleUrls: ['./table-payments.component.scss']
})
export class TablePaymentsComponent implements OnInit {
	@Input() searchTerm = '';
	@Input() idDoctor!: string;
  @Input() status: string|null = null;
  @Input() dateRange: { start: moment.Moment | null; end: moment.Moment | null; } = {
    start: null,
    end: null
  }

	loading = false;
  list: PaymentSearchDataResponse[] = [];
  paymentMethods = PaymentMethods;

	columns = [
		{
			slug: 'doctorName',
			order: true,
			title: 'ESPECIALISTA'
		},
		{
			slug: 'patientName',
			order: true,
			title: 'PACIENTE'
		},
		{
			slug: 'value',
			order: true,
			title: 'VALOR'
		},
		{
			slug: 'createdAt',
			order: true,
			title: 'DATA E HORÁRIO'
		},
		{
			slug: 'paymentMethod',
			order: true,
			title: 'TIPO PGTO'
		},
		{
			slug: 'chargeLastFourDigits',
			order: true,
			title: 'CARTÃO'
		},
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.searchPayments();
    this.columns[0].order = !this.idDoctor;
  }

  ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, dateRange, status } = changes;
		if (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
			this.onSearch();
		} else if(status?.currentValue !== status?.previousValue) {
			this.onSearch();
    } else if(this._dateRangeChangedValue(dateRange)) {
			this.onSearch();
    }
	}

  private _dateRangeChangedValue(dateRange): boolean {
    const { currentValue, previousValue } = dateRange;

    return currentValue?.start !== previousValue?.start ||
      currentValue?.end !== previousValue?.end;
  }

  onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.searchPayments();
	}

  private searchPayments() {
    this.initOrStopLoading()
    this.pageControl.count = 0;

    const body = this.setBody();

    this.paymentService.searchPayments(this.pageControl, body)
      .pipe(finalize(() => this.initOrStopLoading()))
      .subscribe(
        res => this.dataHandler(res),
        err => this.errorHandler()
      )
  }

  private setBody() {
    let body = {}

    if(this.idDoctor) {
      body = {
        doctorId: this.idDoctor
      }
    }

    body = { ...body, ...this.dateRange, status: this.status }

    return body;
  }

  private dataHandler(res: PaymentSearchResponse) {
    this.pageControl.count = res.totalElements;
		this.list = res.data;
  }

  private errorHandler() {
		this.list = [];
		this.pageControl.count = 0;
  }

  private initOrStopLoading(): void {
    this.loading = !this.loading;
  }

	private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
		this.searchPayments();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.searchPayments();
	}

  reverseMoney(payment: PaymentSearchDataResponse) {

    this.openDialogCancelPayment(payment)
      .subscribe(confirm => {
        if(confirm.res) {
          this.initOrStopLoading();
          this.cancelPayment(payment.id)
            .pipe(finalize(() => this.initOrStopLoading()))
            .subscribe(
              response => this.handleCancelPaymentResponse(response),
              err => this.handleCancelPaymentError(err)
            )
        }
      });

  }

  private handleCancelPaymentResponse(response): void {
    if(response.success){
      this.toastr.info(response.message);
    }else{
      this.toastr.error(response.message);
    }

		this.searchPayments();
  }

  private handleCancelPaymentError(err): void {
    if(!err.ok){
      this.toastr.error('Ocorreu um problema ao tentar estornar este pagamento');
    }
  }

  private cancelPayment(id: string): Observable<any> {
    return this.paymentService.cancelPayment(id);
  }

  private openDialogCancelPayment(payment: PaymentSearchDataResponse): Observable<{res: boolean}> {
    const dialogConfig: MatDialogConfig = {
      maxWidth: '90%',
      width: '600px',
      hasBackdrop: true,
      closeOnNavigation: true,
      data: {
        afterPeriod: payment.afterPeriod
      }
    };
    const dialogRef = this.dialog.open(DialogConfirmCancelPayment, dialogConfig);

    return dialogRef.afterClosed();
  }

  setColorStatusPayment(status: string): string {
    if(status === PaymentStatus.SUCCESS){
      return '#4CAF4B';
    }

    if(status === PaymentStatus.REVERSED){
      return '#FFC727';
    }

    if(status === PaymentStatus.PENDING){
      return '#3A9BD9';
    }

    if(status === PaymentStatus.CANCELED){
      return '#E0DEDC';
    }

    if(status === PaymentStatus.ERROR){
      return '#E54E4E';
    }

    return '#E54E4E';
  }

  canReverseMoney(status: string): boolean {
    return status === PaymentStatus.PENDING || status === PaymentStatus.SUCCESS;
  }

  cpfMask(value: string): string {
    if(value) return setCpfMask(value);
    return '';
  }

  setDatePayment(value: string): { date: string, time: string, dayOfWeek: string } {
    const val = moment(value);

    const date = val.format('DD/MM/YYYY');
    const time = val.format('HH:mm');
    const dayOfWeek = val.format('dddd');

    return {date, time, dayOfWeek};
  }

  setDayOfWeekToPorguese(value: string): string {
    return Utils.dayOfWeekToPortuguese(value);
  }

}

@Component({
  selector: 'dialog-confirm-cancel-payment',
  templateUrl: './dialog-confirm-cancel-payment.component.html',
  styleUrls: ['./table-payments.component.scss']
})
class DialogConfirmCancelPayment implements OnInit {

  isAfterPeriod: boolean = false;
  beforePeriodTxt = 'Tem certeza que deseja cancelar? Em caso de cancelamento no período de 48 horas da data da consulta, será cobrada uma taxa de 30% do valor total pago.';
  afterPeriodTxt = 'Este agendamento já está dentro do perído de 48 horas, em caso de cancelamento, será cobrada a taxa de 30% do valor total pago.';

  constructor(
    private readonly dialogRef: MatDialogRef<DialogConfirmCancelPayment>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.isAfterPeriod = this.data.afterPeriod;
  }

  onNoClick(): void {
    this.dialogRef.close({res: false});
  }

  onConfirmClick(): void {
    this.dialogRef.close({res: true});
  }

}
