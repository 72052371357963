import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfo } from '@app-store/session/session.store';
import { SessionService } from '@app-store/session/session.service';
import { SessionQuery } from '@app-store/session/session.query';
import { SidebarService } from '@app-services/sidebar.service';
import { UserInfoRoles } from '@app-shared/enums/others';
import { NavigationStart, Router } from '@angular/router';

@Component({
	selector: 'app-layout-restrict-area-header',
	templateUrl: './layout-restrict-area-header.component.html',
	styleUrls: ['./layout-restrict-area-header.component.scss']
})
export class LayoutRestrictAreaHeaderComponent implements OnInit {
	isAuthenticated$: Observable<boolean>;
	userInfo$: Observable<UserInfo>;
  userId: string;

  @Input() incompleteProfile = false;

  menuItems = [
    {
      title: 'Sair',
      link: '/logout'
    },
  ]

  isBrowser = false;

	constructor(
		private sidebarService: SidebarService,
		private sessionService: SessionService,
		private sessionQuery: SessionQuery,
    private elementRef: ElementRef,
    private router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any
	) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

	ngOnInit() {
    this.insertExpertProfile();
		this.isAuthenticated$ = this.sessionQuery.isAuthenticated$;
		this.userInfo$ = this.sessionQuery.userInfo$;

    this.userInfo$.subscribe(info => {
      if(info) {
        this.userId = info.id;
        this._setHeaderColor(this.router.url, info.role);
      }
    })

    this._initRouteListener();
	}

  insertExpertProfile() {
    this.sessionQuery.userInfo$.subscribe(info => {
      if (info && info.role === UserInfoRoles.DOCTOR && !this.menuItems.find(i => i.link === '/painel/experts')) {
        this.menuItems.splice(0, 0, {
          title: 'Meu perfil',
          link: `/painel/experts/profile/${info.id}`
        },);
      }
		});
  }

	toggelSidebar() {
		this.sidebarService.active = !this.sidebarService.active;
	}

	signout() {
		this.sessionService.logout();
	}

  private _initRouteListener(): void {
    this.router.events.subscribe((route: NavigationStart) => {
      if(route instanceof NavigationStart) {
        this.userInfo$.subscribe(info => {
          if (info){
            this._setHeaderColor(route.url, info.role);
          }
        })
      }
    })
  }

  private _setHeaderColor(routeUrl:string, role): void {
    if (this.isBrowser)
    {
      if (routeUrl.startsWith('/painel') && role === UserInfoRoles.ADMIN){
        this._setDoctorPrimaryColor();
      } else {
        this._setDefaultPrimaryColor();
      }
    }
  }

  private _setDoctorPrimaryColor(): void {
    this.elementRef.nativeElement.style.setProperty('--primary', '#7CB8D1');
  }

  private _setDefaultPrimaryColor(): void {
    this.elementRef.nativeElement.style.setProperty('--primary', '#57A1A1');
  }

  routeToProfile(): void {
    this.router.navigate([`/painel/experts/profile/${this.userId}`])
  }
}
