export interface Bank {
  id: string;
  label: string;
  value: string;
}

export interface BankAccount {
  id?: string;
  doctorId?: string;
  name?: string;
  document: string;
  bank: string;
  accountCode?: string;
  accountNumber: string;
  accountCheckDigit: string;
  branchNumber: string;
  branchCheckDigit: string;
  type: BankAccountTypes.INDIVIDUAL | BankAccountTypes.COMPANY;
  receiverCode?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum BankAccountTypes {
  INDIVIDUAL = 'individual',
  COMPANY = 'company'
}

export interface BankAccountResponse {
  data?: BankAccount;
  errors?: {field: string, message: string}[];
  message: string;
  success: boolean;
}
