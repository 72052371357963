import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';

import { SharedComponentsModule } from '../shared-components.module';
import { DialogNewPatientComponent } from './dialog-new-patient.component';

@NgModule({
  declarations: [
    DialogNewPatientComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    SharedFormsModule,
    SharedComponentsModule,
  ],
})
export class DialogNewPatientModule {}
