import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfPipe'
})
export class CpfPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let cpf = value ? value.replace(/\D/g, '') : '';

    if (cpf && cpf.length === 11) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (!cpf) {
      return '---';
    } else {
      return value;
    }
  }

}
