import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Bank } from '@app-model/bank-account';
import { City, HealthPlan, PetBreed, Specialty, SpecialtyArea } from '@app-model/open-filter';
import { UserPlan } from '@app-model/plan';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class OpenFilterService {
	static isList = new EventEmitter();
	static isHome = new EventEmitter();
	static dataCities = new EventEmitter();
	static dataBanks = new EventEmitter();
	static dataSpecialties = new EventEmitter();
	static dataSpecialtiesArea = new EventEmitter();
	static dataPlans = new EventEmitter();
	static updateDataFilter = new EventEmitter();

	constructor(private http: HttpClient) {}

	isList(state) {
		OpenFilterService.isList.emit(state);
	}

	dataCities(data) {
		OpenFilterService.dataCities.emit(data);
	}

	dataSpecialties(data) {
		OpenFilterService.dataSpecialties.emit(data);
	}

  dataSpecialtiesArea(data) {
		OpenFilterService.dataSpecialtiesArea.emit(data);
	}

  dataPlans(data) {
		OpenFilterService.dataPlans.emit(data);
	}

  dataBanks(data) {
		OpenFilterService.dataBanks.emit(data);
	}

	isHome(state) {
		OpenFilterService.isHome.emit(state);
	}

  getCityByNameAndUf(cityName: string, uf: string): Observable<City> {
		return this.http.get<City>(`${environment.api}/open/city?name=${cityName}&uf=${uf}`, {});
	}

  getCitiesOptions(query: string): Observable<City[]> {
		return this.http.get<City[]>(`${environment.api}/open/cityOptions?query=${query}`, {}).pipe(take(1));
	}

	getCities(query: string): Observable<City[]> {
		return this.http.get<City[]>(`${environment.api}/open/cities/search?query=${query}`, {}).pipe(take(1));
	}

	getSpecialtiesOptions(query: string): Observable<Specialty[]> {
		return this.http.post<Specialty[]>(`${environment.api}/open/specialtyOptions?query=${query}`, {}).pipe(take(1));
	}

  getSpecialtiesArea(query: string): Observable<SpecialtyArea[]> {
		return this.http.get<SpecialtyArea[]>(`${environment.api}/open/specialtiesArea/search?query=${query}`, {}).pipe(take(1));
	}

  getSpecialtiesByArea(areaId: string, query: string): Observable<any[]> {
		return this.http.get<any[]>(`${environment.api}/open/specialtyByArea?areaId=${areaId}&limited=false&query=${query}`, {}).pipe(take(1));
	}

  getSpecialties(query: string): Observable<Specialty[]> {
		return this.http.get<Specialty[]>(`${environment.api}/open/specialties/search?query=${query}`, {}).pipe(take(1));
	}

  getHealthPlans(query: string): Observable<HealthPlan[]> {
    return this.http.get<HealthPlan[]>(`${environment.api}/open/healthplans/search?query=${query}`, {}).pipe(take(1));;
  }

  getBanks(query: string): Observable<Bank[]> {
		return this.http.get<Bank[]>(`${environment.api}/open/banks/search?query=${query}`, {}).pipe(take(1));
	}

  getUserPlans(): Observable<UserPlan[]> {
		return this.http.get<UserPlan[]>(`${environment.api}/open/plans`, {}).pipe(take(1));
	}

  getPetBreeds(query: string): Observable<PetBreed[]> {
		return this.http.get<PetBreed[]>(`${environment.api}/open/pet/search/${query}`, {}).pipe(take(1));
  }
}
