import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';
import { DialogPaymentExpertComponent } from './dialog-payment-expert.component';

@NgModule({
  declarations: [
    DialogPaymentExpertComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    SharedFormsModule,
  ],
})
export class DialogPaymentExpertModule {}
