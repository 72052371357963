import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePatientCreditsComponent } from './table-patient-credits.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';



@NgModule({
  declarations: [TablePatientCreditsComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    SharedPipesModule,
    MatButtonModule,
    MatTooltipModule,
    ClipboardModule
  ],
  exports: [TablePatientCreditsComponent]
})
export class TablePatientCreditsModule { }
