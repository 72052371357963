export function phoneMask(value) {
	if (!value) {
		return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	}
	const numbers = value.replace(/\D/g, '').toString();
	if (numbers.length === 10) {
		return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	}
	return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export function setPhoneMask(v: string): string {

  v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito

  const len = v.length;

  v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos

  if(len === 10){
    v=v.replace(/(\d{4})(\d)/g,"$1-$2");//Coloca hífen entre o quarto e o quinto dígitos
  }

  if(len === 11){
    v=v.replace(/(\d{5})(\d)/g,"$1-$2");//Coloca hífen entre o quarto e o quinto dígitos
  }

  return v;
}
