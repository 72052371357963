import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScheduleService } from '@app-services/schedule.service';

@Component({
  templateUrl: './dialog-free-time.component.html',
  styleUrls: ['./dialog-free-time.component.scss']
})
export class DialogFreeTimeComponent implements OnInit {
  dataDoctorSeach: any;
  doctors = [];
  loading = false;
  constructor(
    private dialogRef: MatDialogRef<DialogFreeTimeComponent>,
		@Inject(MAT_DIALOG_DATA) data,
    private readonly scheduleService: ScheduleService,
    private readonly router: Router,
  ) {
    this.dataDoctorSeach = data;
  }

  ngOnInit(): void {
    this.loading = true;
    this.scheduleService.getDoctorsByClinicId(this.dataDoctorSeach).subscribe(
      doctors => {
        this.doctors = doctors;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  selectDoctor(c){
    this.close();
    this.router.navigate(['painel/schedule/form'], {queryParams: { sta: this.dataDoctorSeach.startStr, end: this.dataDoctorSeach.endStr, doc: c.id, sch: c.schedules[0].id }});
  }


  close() {
		this.dialogRef.close(false);
	}

}
