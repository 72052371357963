import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomPayment } from '@app-model/payment';
import { OpenFilterService } from '@app-services/open-filter.service';
import { PatientService } from '@app-services/patient.service';
import { Utils } from '@app-shared/utils';
import { CustomValidator } from '@app-shared/validators/custom';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-patient-add-credits',
  templateUrl: './dialog-patient-add-credits.component.html',
  styleUrls: ['./dialog-patient-add-credits.component.scss']
})
export class DialogPatientAddCreditsComponent implements OnInit {
	form: FormGroup;

  infoCredits = 'Os créditos são bônus que os profissionais da saúde atribuem a determinados pacientes para que os mesmos possam fazer agendamentos sem passar pelo processo de pagamento e checkout.';
  infoCreditsPayment = 'Atenção: O valor cobrado é referente ao número total de créditos selecionado.';

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<DialogPatientAddCreditsComponent>,
    private readonly openFilterService: OpenFilterService,
    private readonly dialog: MatDialog,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      credits: [0, [Validators.required, CustomValidator.numeric, Validators.min(0)]],
      doCharge: [false, [Validators.required]],
      amount: [null]
    })
  }

  parseInt(value): void {
    this.form.patchValue({credits: parseInt(value)})
  }

  get doCharge(): boolean {
    return this.form.get('doCharge').value;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: FormGroup, value: CustomPayment): void {
    if(Utils.checkIsValidForm(form)) {
      this.dialogRef.close(value);
    }
  }

}
