<div class="input-with-btn-container">
  <label class="form-label">
    <i class="fal {{labelIcon}}"></i>
    {{label}}
  </label>
  <div class="input-with-btn mb-3">
    <mat-select class="select-input" (selectionChange)="change($event)" placeholder="{{placeholder}}" [(ngModel)]="tag"
      matInput>
      <mat-option>
        <ngx-mat-select-search [formControl]="searchAutocomplete" placeholderLabel="Pesquisar.."
          noEntriesFoundLabel="{{autocompleteItemName}} não encontrado">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredAutocomplete | async" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
    <button (click)="addTag()" type="button">
      <i class="fal fa-plus"></i>
    </button>
  </div>
  <!-- <div class="input-with-btn mb-3">
    <input type="text" (keydown.enter)="addTag()" placeholder="{{placeholder}}" [(ngModel)]="tag"
      (ngModelChange)="change($event)" [matAutocomplete]="auto">
    <button (click)="addTag()" type="button">
      <i class="fal fa-plus"></i>
    </button>
  </div>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of autocomplete" [value]="option.name">
      {{option.name}}
    </mat-option>
  </mat-autocomplete> -->
  <div class="row">
    <ng-container *ngFor="let item of tags; let i = index">
      <div class="tags-container">
        <span>{{item}}</span>
        <div (click)="removeTag(i)"><i class="fal fa-times"></i></div>
      </div>
    </ng-container>
  </div>
</div>
