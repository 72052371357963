import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DialogCropImageComponent } from './dialog-crop-image.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DialogCropImageComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ImageCropperModule
  ]
})
export class DialogCropImageModule {}
