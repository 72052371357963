<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="email" placeholder="E-mail"
              (blur)="getManagerByEmail($event.target.value)">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="name" placeholder="Nome completo">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="telephone" placeholder="Telefone" mask="(00) 0000-0000||(00) 00000-0000">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="cpf" placeholder="CPF" mask="000.000.000-00">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="birthDate" placeholder="Data de nascimento" mask="00/00/0000">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form, form.getRawValue())" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>