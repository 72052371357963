import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentaryPageableResponse } from '@app-model/commentary';
import { Doctor, DoctorDataDefaultResponse, DoctorPageableResponse } from '@app-model/doctor';
import { PageControl } from '@app-model/page-control';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PreDoctor } from '../model/doctor';

interface CreateDoctor {
  email: string;
  name: string;
  telephone: string;
  password: string
}

@Injectable({
	providedIn: 'root'
})
export class DoctorService {
	constructor(private http: HttpClient) {}

  create(data: PreDoctor): Observable<any> {
    return this.http.post(`${environment.api}/doctor`, data);
  }

  createLogin(data: CreateDoctor): Observable<any> {
    return this.http.post(`${environment.api}/login/createDoctor`, data);
  }

  update(data: Doctor): Observable<any> {
    return this.http.put(`${environment.api}/doctor`, data);
  }

  uploadImage(file: File, id: string): Observable<any> {
    const body = new FormData()
    body.append('file', file);

    return this.http.post(`${environment.api}/doctor/${id}/image-upload`, body);
  }

	doctorSearch(): Observable<Doctor[]> {
		return this.http.post<DoctorPageableResponse>(`${environment.api}/doctor/search`, {sortField: "name"}).pipe(take(1), map((response: DoctorPageableResponse) => response.data));
	}

	paginate(pageControl: PageControl, customFilter?): Observable<DoctorPageableResponse[]> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
			value: pageControl.value,
      ...customFilter
		};

		return this.http.post<DoctorPageableResponse[]>(`${environment.api}/doctor/search`, body).pipe(take(1));
	}

	getById(idDoctor: string): Observable<Doctor> {
		return this.http.get<DoctorDataDefaultResponse>(`${environment.api}/doctor/${idDoctor}`).pipe(take(1), map((response: DoctorDataDefaultResponse) => response.data));
	}

	getPatientCredits(idDoctor: string, idPatient: string): Observable<any> {
		return this.http.get<any>(`${environment.api}/doctor/${idDoctor}/patient/${idPatient}/get-credits`);
	}

	schedulingSearch(idSchedule: string): Observable<any> {
		return this.http.post(`${environment.api}/schedule/${idSchedule}/scheduling/search`, {});
	}

  inactiveErrors(idDoctor: string): Observable<any> {
    return this.http.get(`${environment.api}/doctor/inactive-errors/${idDoctor}`);
  }

  updateCredits(params: {doctorId: string; patientId: string; credits?: number}): Observable<any> {
    return this.http.post(`${environment.api}/doctor/${params.doctorId}/patient/${params.patientId}/update-credits`, params);
  }

  doctorSchedulingFeedback(pageControl: PageControl, doctorId: string): Observable<CommentaryPageableResponse[]> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
			value: pageControl.value
		};

		return this.http.post<CommentaryPageableResponse[]>(`${environment.api}/doctor/${doctorId}/scheduling-feedback/search`, body);
	}

  deleteDoctorSchedulingFeedback(doctorId: string, schedulingFeedbackId): Observable<any> {
		return this.http.delete<any>(`${environment.api}/doctor/${doctorId}/scheduling-feedback/${schedulingFeedbackId}/delete`);
	}
}
