import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogScheduleFaleComASiComponent } from './dialog-schedule-fale-com-a-si.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    DialogScheduleFaleComASiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    SharedFormsModule,
    CurrencyMaskModule,
    MatTooltipModule
  ]
})
export class DialogScheduleFaleComASiModule {}
