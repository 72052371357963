import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Bank, BankAccount, BankAccountTypes } from '@app-model/bank-account';
import { OpenFilterService } from '@app-services/open-filter.service';
import { FormControlStatus } from '@app-shared/enums/form';
import { cpfMask, cnpjMask, cpfCnpjMask } from '@app-shared/masks';
import { DocumentValidator } from '@app-shared/validators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Utils } from '@app-shared/utils';

@Component({
  selector: 'app-dialog-bank-account-expert',
  templateUrl: './dialog-bank-account-expert.component.html',
  styleUrls: ['./dialog-bank-account-expert.component.scss']
})
export class DialogBankAccountExpertComponent implements OnInit {

  form: FormGroup;

  documentType = BankAccountTypes.INDIVIDUAL;

  cpfMask = {
		guide: true,
    mask: cpfMask
  }

  cnpjMask = {
		guide: true,
    mask: cnpjMask
  }

  cpfCnpjMask = {
    guide: false,
    mask: cpfCnpjMask
  }

  searchBank = new FormControl();
	filteredBanks: Observable<Bank>;
  banks: Bank[] = [];

  utils = Utils;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<DialogBankAccountExpertComponent>,
    private readonly openFilterService: OpenFilterService,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      bank: [null, [Validators.required]],
      name: [null, [Validators.required]],
      document: [null, [Validators.required, DocumentValidator.cpfCnpj]],
      accountNumber: [null, [Validators.required]],
      accountCheckDigit: [null, [Validators.required]],
      branchNumber: [null, [Validators.required]],
      branchCheckDigit: [null],
      type: [BankAccountTypes.INDIVIDUAL]
    })

    this._fillData(this.data);
    this._fillBanks();
  }

  setLabelAndMaskDocument(value: BankAccountTypes): void {
    this.documentType = value;
    this.form.controls['document'].setValue(null);
    this.form.controls['document'].clearValidators();

    if(value === BankAccountTypes.INDIVIDUAL){
      this.form.controls['document'].setValidators([Validators.required, DocumentValidator.cpf]);
    }

    if(value === BankAccountTypes.COMPANY){
      this.form.controls['document'].setValidators([Validators.required, DocumentValidator.cnpj]);
    }
  }

  bankChange(e: MatSelectChange) {
    const bank = this.banks.find(bank => bank.value === e.value);
  }

  private _fillBanks(): void {
    this.openFilterService.getBanks('')
      .subscribe(
        banks => {
          this.banks = banks;
          this.openFilterService.dataBanks(banks);
          this._initAutocompletes();
        }
      )
  }

  private _initAutocompletes() {
    this.filteredBanks = this.searchBank.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(this.banks, value)
      })
    )
	}

  private _filter(items, value, field = 'label') {
		if (typeof value === 'string') {
			const filterValue = value
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
			return items.filter(option => {
				return (
					filterValue === '' ||
					String(option[field])
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(filterValue)
				);
			});
		}
	}

  private _fillData(data: BankAccount): void {
    if(data){
      const { bank, name, document, accountNumber, accountCheckDigit, branchNumber, branchCheckDigit, type } = data;

      this.setLabelAndMaskDocument(type);

      this.form.patchValue({
        bank, name, document, accountNumber, accountCheckDigit, branchNumber, branchCheckDigit, type
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick({status, value, controls}: FormGroup): void {
    if(status === FormControlStatus.VALID){
      this.dialogRef.close(value);
    }

    if(status === FormControlStatus.INVALID) {
      let message = 'Os seguintes campos são obrigatórios: ';
      Object.entries(controls).forEach(([key, value] : [string, FormControl]) => {
        if(!value.valid){
          if(key === 'bank') message += 'Banco, '
          if(key === 'name') message += 'Nome do titular, '
          if(key === 'document') message += 'CPF do titular, '
          if(key === 'accountNumber') message += 'Número da conta, '
          if(key === 'accountCheckDigit') message += 'Dígito verificador, '
          if(key === 'branchNumber') message += 'Agência, '
        }
      })
      message += 'preencha todos corretamente antes de continuar.'

      this.toastr.warning('', message);
    }
  }

}
