import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogOnboardingValuesComponent } from './dialog-onboarding-values.component';

@NgModule({
  declarations: [DialogOnboardingValuesComponent],
  imports: [
    CommonModule,
    MatDialogModule,
  ]
})
export class DialogOnboardingValuesModule {}
