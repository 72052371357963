import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { parse } from 'date-fns';

@Injectable({
	providedIn: 'root'
})
export class DateFormatAdapter extends NativeDateAdapter {
	override useUtcForDisplay = true;
	override parse(value: any): Date | null {
		if (typeof value === 'string' && value.indexOf('/') > -1) {
			return parse(value, 'dd/MM/yyyy', new Date());
		}
		const timestamp = typeof value === 'number' ? value : Date.parse(value);
		return isNaN(timestamp) ? null : new Date(timestamp);
	}

	override deserialize(value) {
		if (!value) {
			return null;
		}
		if (typeof value === 'string' && value.indexOf('/') > -1) {
			return parse(value, 'dd/MM/yyyy', new Date());
		}

		return new Date(value);
	}
}

export const PICK_FORMATS = {
	parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
	display: {
		dateInput: 'input',
		monthYearLabel: { year: 'numeric', month: 'short' },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' }
	}
};
