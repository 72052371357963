import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class DialogCustomConfigs {

	static getDialogCustomConfig = (): MatDialogConfig => {
    return {
      maxWidth: '90%',
      width: '1200px',
      maxHeight: '90%',
      height: '800px',
      hasBackdrop: true,
      closeOnNavigation: true
    }
	};
}
