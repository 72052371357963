import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { TablePaymentsComponent } from './table-payments.component';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [TablePaymentsComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    SharedPipesModule,
    MatDialogModule,
    ToastrModule
  ],
  exports: [TablePaymentsComponent],
})
export class TablePaymentsModule {}
