import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePatientHistoryComponent } from './table-patient-history.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';



@NgModule({
  declarations: [TablePatientHistoryComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    SharedPipesModule
  ],
  exports: [TablePatientHistoryComponent]
})
export class TablePatientHistoryModule { }
