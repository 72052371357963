import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-schedule',
  templateUrl: './select-schedule.component.html',
  styleUrls: ['./select-schedule.component.scss']
})
export class SelectScheduleComponent implements OnInit {
  @Output() openDialogSearchSchedule = new EventEmitter<any>();
  @Input() type: string = '';
  @Input() name: string = '';
  @Input() address: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  openDialog(){
    this.openDialogSearchSchedule.emit();
  }

}
