<mat-dialog-content>
  <div class="container p-2 align-middle">
    <form [formGroup]="form" class="p-2" appInvalidControlScroll>
      <div class="row">
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h1>Agenda da Si</h1>
            </div>
            <div class="col-md-6 text-right">
              <button type="button" class="btn btn-close" (click)="onNoClick()">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-2" *ngIf="!isOnlyHomecare()">
          <div class="label mb-2">
            <i class="fal fa-stopwatch me-2"></i>
            <span>Defina o tempo de atendimento</span>
          </div>
          <mat-form-field>
            <mat-label>Selecionar tempo</mat-label>
            <mat-select formControlName="slot" (ngModelChange)="createTimes()">
              <mat-option *ngFor="let slot of slots" [value]="slot.value">
                {{slot.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 mt-2" *ngIf="scheduleTypeMapGlobal.homecare">
          <div class="label mb-2">
            <i class="fal fa-stopwatch me-2"></i>
            <span>Tempo de Atendimento Domiciliar</span>
          </div>
          <mat-form-field>
            <mat-label>Selecionar tempo</mat-label>
            <mat-select formControlName="slotHomecare" (ngModelChange)="createTimes()">
              <mat-option *ngFor="let slot of slotsDomiciliar" [value]="slot.value">
                {{slot.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="label mb-2">
            <i class="fal fa-calendar-check me-2"></i>
            <span>Configurar Atendimento</span>
          </div>
        </div>
      </div>
      <section class="row mt-2">
        <article class="mx-3">
          <input type="checkbox" id="sunday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.SUNDAY">
          <div><span>Domingo</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="monday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.MONDAY">
          <div><span>Segunda</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="tuesday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.TUESDAY">
          <div><span>Terça</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="wednesday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.WEDNESDAY">
          <div><span>Quarta</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="thursday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.THURSDAY">
          <div><span>Quinta</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="friday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.FRIDAY">
          <div><span>Sexta</span></div>
        </article>
        <article class="mx-3">
          <input type="checkbox" id="saturday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.SATURDAY">
          <div><span>Sábado</span></div>
        </article>
      </section>
      <div class="row mt-5 ps-4">
        <div class="col-md-12">
          <div class="label mb-2">
            <span class="me-2">Tipo de Atendimento</span>
            <i class="fal fa-info-circle" matTooltipHideDelay="1000" [matTooltip]="infoScheduleType"></i>
          </div>
        </div>
        <!-- <div class="col-md-8 info-container mx-3 py-2 px-3">

        </div> -->
        <div class="col-md-12 mt-3">
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.commum" value="commum"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isHomecare()" *ngIf="!isOnlyHomecare()">
            Presencial
          </mat-checkbox>
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.online" value="online"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isHomecare()" *ngIf="!isOnlyHomecare()">
            Online
          </mat-checkbox>
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.homecare" value="homecare"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isCommonOrOnline()"
            *ngIf="scheduleTypeMapGlobal.homecare">
            Domiciliar
          </mat-checkbox>
        </div>
      </div>
      <div class="row mt-5 ps-4">
        <div class="col-md-12">
          <div class="label mb-2">
            <span class="me-2">Horários de Atendimento</span>
            <i class="fal fa-info-circle" matTooltipHideDelay="1000" [matTooltip]="infoScheduleTime"></i>
          </div>
        </div>
        <!-- <div class="col-md-8 info-container mx-3 py-2 px-3">
          Com a Si você concentra a sua agenda em um só lugar, sem conflito. Configure seu calendário e defina horários
          exclusivos para os pacientes do Saúde Integral.
        </div> -->
        <div class="col-md-12 mt-3">
          <div class="row align-items-center">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Abre à(s)</mat-label>
                <mat-select (valueChange)="handleChangeTime($event, 'startTime')">
                  <mat-option *ngFor="let time of times" [value]="time">
                    {{time}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width">
                <input matInput min="00:00" max="23:00" step="900" type="time" placeholder="Abre à(s)"
                  mask="00:00" (change)="handleChangeTime($event.target.value, 'startTime')">
              </mat-form-field> -->
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Fecha à(s)</mat-label>
                <mat-select (valueChange)="handleChangeTime($event, 'endTime')">
                  <mat-option *ngFor="let time of times" [value]="time">
                    {{time}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width">
                <input matInput type="time" placeholder="Fecha à(s)" mask="00:00"
                  (change)="handleChangeTime($event.target.value, 'endTime')">
              </mat-form-field> -->
            </div>
            <div class="col-md-4">
              <button mat-flat-button color="primary" style="color: #ffffff;" (click)="submitScheduleTime()">
                <i class="fal fa-plus"></i>
                Adicionar horário
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let setting of settings; let i = index">
          <div class="col-auto mt-2">
            <div class="tags-container">
              <div (click)="removeTag(i)" style="cursor: pointer;"><i class="fal fa-times me-2"></i></div>
              <span>{{tagContent(setting)}}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <form [formGroup]="schedules">
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="sundayCheck">Domingo</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.sundayCheck">
            <ng-container formArrayName="sunday"
              *ngFor="let schedule of schedules.get('sunday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'sunday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'sunday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('sunday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('sunday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="mondayCheck">Segunda-feira</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.mondayCheck">
            <ng-container formArrayName="monday"
              *ngFor="let schedule of schedules.get('monday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'monday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'monday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('monday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('monday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="tuesdayCheck">Terça-feira</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.tuesdayCheck">
            <ng-container formArrayName="tuesday"
              *ngFor="let schedule of schedules.get('tuesday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'tuesday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'tuesday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('tuesday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('tuesday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="wednesdayCheck">Quarta-feira</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.wednesdayCheck">
            <ng-container formArrayName="wednesday"
              *ngFor="let schedule of schedules.get('wednesday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'wednesday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'wednesday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('wednesday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('wednesday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="thursdayCheck">Quinta-feira</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.thursdayCheck">
            <ng-container formArrayName="thursday"
              *ngFor="let schedule of schedules.get('thursday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'thursday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'thursday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('thursday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('thursday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="fridayCheck">Sexta-feira</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.fridayCheck">
            <ng-container formArrayName="friday"
              *ngFor="let schedule of schedules.get('friday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'friday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'friday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('friday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('friday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-2">
            <mat-checkbox formControlName="saturdayCheck">Sábado</mat-checkbox>
          </div>
          <ng-container *ngIf="schedules.value.saturdayCheck">
            <ng-container formArrayName="saturday"
              *ngFor="let schedule of schedules.get('saturday')['controls']; let i = index; let l = count;">
              <ng-container [formGroupName]="i">
                <div *ngIf="i > 0" class="col-md-2"></div>
                <div class="col-md-2" *ngIf="scheduleTypeMap.homecare">
                  <mat-slide-toggle formControlName="homecare">
                    Domiciliar
                  </mat-slide-toggle>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Abre à(s)" formControlName="start" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'saturday', 'start')">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="example-full-width">
                    <input matInput type="time" placeholder="Fecha à(s)" formControlName="end" mask="00:00"
                      (blur)="isValidTime($event.target.value, 'saturday', 'end')">
                  </mat-form-field>
                </div>
                <div class="col-md-1">
                  <button class="btn-remove" (click)="removeScheduleDay('saturday', i)" mat-button>
                    <i class="fal fa-times"></i>
                  </button>
                </div>
                <div class="col-md-3">
                  <ng-container *ngIf="(i + 1) == l">
                    <div (click)="addScheduleDay('saturday')">
                      <i class="fal fa-plus"></i>
                      Adicionar Horários
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </form> -->
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
