import slugify from 'slugify';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DayOfWeek, NumberOfDay } from './enums/dayOfWeek';
import imageCompression from 'browser-image-compression';
import { AttendanceType } from './enums/schedulings';
import { FormControl, FormGroup } from '@angular/forms';
import { FormControlStatus } from './enums/form';
import { ScheduleTypeMap } from '@app-model/clinic';

export class Utils {
	static isMobile() {
		return window && window.matchMedia('(max-width: 991px)').matches;
	}
	static ngbDateToDate(ngbDate: { month; day; year }) {
		if (!ngbDate) {
			return null;
		}
		return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
	}
	static dateToNgbDate(date: Date) {
		if (!date) {
			return null;
		}
		date = new Date(date);
		return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
	}
	static scrollToTop(selector: string) {
		if (document) {
			const element = document.querySelector(selector) as HTMLElement;
			element.scrollTop = 0;
		}
	}
	static genId() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	static downloadPDF(blob) {
		if (blob) {
			const file = new Blob([blob], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);

			// let link = document.createElement('a');
			// link.href = url;
			// link.download = `Termos_de_uso_SI_–_SAÚDE_INTEGRAL_LTDA.pdf`;
			// link.click();

			if ((window.navigator as any).msSaveOrOpenBlob) {
				(window.navigator as any).msSaveOrOpenBlob(file, url.split(':')[1] + '.pdf');
		  } else {
				window.open(url);
		  }
		}
	}

	static slug(text: string) {
		return slugify(text, {
			lower: true
		});
	}

	static addressMaps(address: string, number: string, neighborhood: string, city: string, uf: string): string {
		let addressMaps = '';
		address && (addressMaps = address);
		number && (addressMaps += ` ${number}`);
		neighborhood && (addressMaps += ` ${neighborhood}`);
		city && (addressMaps += ` ${city}`);
		uf && (addressMaps += ` ${uf}`);
		addressMaps.length && (addressMaps = addressMaps.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));

		return `https://maps.google.com?q=${addressMaps}`;
	}


	static dayOfWeek(date) {
		if(date) {
			const dayOfWeek = format(new Date(date), 'ccc', { locale: ptBR }).slice(0, 3).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
			const dayAndMonth = format(new Date(date), 'dd/LL', { locale: ptBR });
			return `${dayOfWeek} ${dayAndMonth}`;
		}

		return '';
	}

	static timeOfDay(date) {
		if(date) {
			const d = new Date(date.year, date.month, date.day, date.hour, date.minute);
			return format(d, 'HH:mm', { locale: ptBR });
		}

		return '';
	}

	static titleFormatCalendar(args) {
		if(args) {
			const s = new Date(args.start.year, args.start.month, args.start.day, args.start.hour, args.start.minute);
			const e = new Date(args.end.year, args.end.month, args.end.day, args.end.hour, args.end.minute);
			const yearStart = format(s, 'YYY', { locale: ptBR });
			const yearEnd = format(e, 'YYY', { locale: ptBR });
			const monthStart = format(s, 'MMMM', { locale: ptBR }).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
			const monthEnd = format(e, 'MMMM', { locale: ptBR }).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
			const dayStart = format(s, 'dd', { locale: ptBR });
			const dayEnd = format(e, 'dd', { locale: ptBR });

			if(yearStart === yearEnd) {
				if(monthStart === monthEnd) {
					return `${monthStart} ${dayStart} - ${dayEnd}, ${yearStart}`;
				} else {
					return `${monthStart} ${dayStart} - ${monthEnd} ${dayEnd}, ${yearStart}`;
				}
			} else {
				return `${monthStart} ${dayStart}, ${yearStart} - ${monthEnd} ${dayEnd}, ${yearEnd}`;
			}
		}

		return '';
	}

	static patientHistoryDate(s, e) {
		if(s && e) {
			const dateStart = format(new Date(s), 'dd/MM/yyyy');
			const hourStart = format(new Date(s), 'HH', { locale: ptBR });
			const minuteStart = format(new Date(s), 'mm', { locale: ptBR });
			// const timeStart = format(new Date(s), 'a', { locale: ptBR }).toLowerCase();

			const dateEnd = format(new Date(e), 'dd/MM/yyyy');
			const hourEnd = format(new Date(e), 'HH', { locale: ptBR });
			const minuteEnd = format(new Date(e), 'mm', { locale: ptBR });
			// const timeEnd = format(new Date(e), 'a', { locale: ptBR }).toLowerCase();


			if(dateEnd > dateStart) {
				return `${dateStart}, ${hourStart}:${minuteStart} - ${dateEnd}, ${hourEnd}:${minuteEnd}`;
			}

			return `${dateStart}, ${hourStart}:${minuteStart} - ${hourEnd}:${minuteEnd}`;
		}
		return '--';
	}

	static patientHistoryDayOfWeek(s, e) {
		if(s && e) {
			const startDayOfWeek = format(new Date(s), 'eeee', { locale: ptBR });
			const endDayOfWeek = format(new Date(e), 'eeee', { locale: ptBR });


			if(endDayOfWeek !== startDayOfWeek) {
				return `${startDayOfWeek} - ${endDayOfWeek}`;
			}

			return `${startDayOfWeek}`;
		}
		return '--';
	}
	static formatAddress(a: string, nu: string, ne: string, c: string, u: string) {
		let address = '';

		a && (address = a);
		nu ? address += `${a && ' '}${nu}${(ne || c || u) &&  ', '}` : address += `${(ne || c || u) &&  ', '}`;
		ne ? address += `${ne}${(ne || c || u) &&  ', '}` : address += `${(c || u) &&  ', '}`;
		c ? address += `${c}${(ne || c || u) &&  ', '}` : address += `${u &&  ', '}`;
		u &&( address += u);

		if(address) {
			return address;
		}

		return '--';
	}

	static eventTitle(args){
		if(args){
			const title = args.event._def.title;
			const timeText = args.timeText;

			if(args.event._def.extendedProps.status === 'WAITING') {
				return `${title}`;
			}

			if(args.event._def.ui.display!=='background') {
				return `${title}, ${timeText}`;
			}


		}

		return '';
	}

	static getPopoverTemplateSetClass(className): string {
		return `<div class="popover ${className}" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`;
	}

	static phoneMaskDDDHandler(phone): string {
		if (phone && phone.replace(/[^0-9]+/g, '').length === 9) {
			return 'cellPhoneSI';
		} else if (phone && phone.replace(/[^0-9]+/g, '').length === 8) {
			return 'phoneSI';
		}

		return '000000000';
	}

	static toContactForm() {
		const elem = document.getElementById('contact');
		const topPos = elem.offsetTop
		window.scrollTo({top:topPos-50, behavior:'smooth'});
	}

	static noAllowWhitespaces(e): boolean {
		if(e.code==='Space'||e.key===" "||e.keyCode===32) {
			return false;
		}
		return true;
	}

  static onlyNumbers(value): string {
    if(value){
      return value.replace(/\D/g, '');
    }

    return '';
  }

  static keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  static keyPressRemoveSpaces(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (!/ /.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  static attendanceTypeToPortuguese(value: string): string {
    switch (value) {
      case AttendanceType.ADULT:
        return 'Adulto';
      case AttendanceType.PEDIATRIC:
        return 'Pediatria';
      default:
        return '';
    }
  }

  static dayOfWeekOnly(date): string {
		if(date) {
			const dayOfWeek = format(new Date(date), 'ccc', { locale: ptBR }).slice(0, 3).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
			return `${dayOfWeek}`;
		}

		return '';
  }

  static dayOfWeekToEnglish(value): string {
    if(value === NumberOfDay.SUNDAY){
      return DayOfWeek.SUNDAY;
    }

    if(value === NumberOfDay.MONDAY){
      return DayOfWeek.MONDAY;
    }

    if(value === NumberOfDay.TUESDAY){
      return DayOfWeek.TUESDAY;
    }

    if(value === NumberOfDay.WEDNESDAY){
      return DayOfWeek.WEDNESDAY;
    }

    if(value === NumberOfDay.THURSDAY){
      return DayOfWeek.THURSDAY;
    }

    if(value === NumberOfDay.FRIDAY){
      return DayOfWeek.FRIDAY;
    }

    if(value === NumberOfDay.SATURDAY){
      return DayOfWeek.SATURDAY;
    }

    return '';
  }

  static dayOfWeekToPortuguese(value): string {
    if(value === DayOfWeek.SUNDAY){
      return 'Domingo';
    }

    if(value === DayOfWeek.MONDAY){
      return 'Segunda-feira';
    }

    if(value === DayOfWeek.TUESDAY){
      return 'Terça-feira';
    }

    if(value === DayOfWeek.WEDNESDAY){
      return 'Quarta-feira';
    }

    if(value === DayOfWeek.THURSDAY){
      return 'Quinta-feira';
    }

    if(value === DayOfWeek.FRIDAY){
      return 'Sexta-feira';
    }

    if(value === DayOfWeek.SATURDAY){
      return 'Sábado';
    }

    return '';
  }

  static numberToDayOfWeekPortuguese(num: number) {
    if(num === NumberOfDay.SUNDAY){
      return 'Dom';
    }

    if(num === NumberOfDay.MONDAY){
      return 'Seg';
    }

    if(num === NumberOfDay.TUESDAY){
      return 'Ter';
    }

    if(num === NumberOfDay.WEDNESDAY){
      return 'Qua';
    }

    if(num === NumberOfDay.THURSDAY){
      return 'Qui';
    }

    if(num === NumberOfDay.FRIDAY){
      return 'Sex';
    }

    if(num === NumberOfDay.SATURDAY){
      return 'Sáb';
    }

    return '';
  }

  static DayOfWeekToNumber(day: string): number {
    if(day === DayOfWeek.SUNDAY){
      return NumberOfDay.SUNDAY;
    }

    if(day === DayOfWeek.MONDAY){
      return NumberOfDay.MONDAY;
    }

    if(day === DayOfWeek.TUESDAY){
      return NumberOfDay.TUESDAY;
    }

    if(day === DayOfWeek.WEDNESDAY){
      return NumberOfDay.WEDNESDAY;
    }

    if(day === DayOfWeek.THURSDAY){
      return NumberOfDay.THURSDAY;
    }

    if(day === DayOfWeek.FRIDAY){
      return NumberOfDay.FRIDAY;
    }

    if(day === DayOfWeek.SATURDAY){
      return NumberOfDay.SATURDAY;
    }

    return -1
  }

  static toBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  static compressImage(file: File): Promise<File> {

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
    }

    return imageCompression(file, options);
  }

  static checkIsValidForm(form: FormGroup): boolean {
    let response = true;
    Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
      if(!value.valid && !(value.status === FormControlStatus.DISABLED)){
        response = false;
        form.get(key).markAsDirty({onlySelf: true})
      }
    });
    return response;
  }

  static dateBrToIso(date: string): string {
    const [day, month, year] = date.split('/');

    return `${year}-${month}-${day}`;
  }



  static getScheduleType(scheduleTypeMap: ScheduleTypeMap): string {
    if(scheduleTypeMap?.commum){
      return 'commum'
    }
    if(scheduleTypeMap?.online){
      return 'online'
    }
    if(scheduleTypeMap?.homecare){
      return 'homecare'
    }

    return '';
  }
}
