<div class="container p-3">
  <div class="row">
    <div class="col-md-12 text-right">
      <button type="button" class="btn btn-close-dialog" (click)="onNoClick()">
        <i class="fal fa-times"></i>
      </button>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-md-12 text-center">
      <h1>Cancelamento</h1>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-md-12 text-center">
      {{ isAfterPeriod ? afterPeriodTxt : beforePeriodTxt }}
    </div>
  </div>
  <div class="row mt-5 btn-submit justify-content-center">
    <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">
      <i class="fal fa-long-arrow-left me-2"></i>
      Voltar
    </button>
    <button type="button" (click)="onConfirmClick()" class="btn mx-3 btn-confirm">
      Confirmar
    </button>
  </div>
</div>
