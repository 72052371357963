<app-layout-restrict-area-header [incompleteProfile]="incompleteProfile"></app-layout-restrict-area-header>
<div class="wrapper d-flex align-items-stretch">
  <app-layout-restrict-area-sidebar [incompleteProfile]="incompleteProfile"></app-layout-restrict-area-sidebar>
  <!-- Page Content  -->
  <div id="content" class="pt-4" [ngClass]="{
      'sidebar-active': sidebarService.active,
      'layout-toggle-sidebar': sidebarService.minSidebar,
      'incomplete-profile': incompleteProfile
    }">
    <router-outlet></router-outlet>
    <div class="flex-grow-1"></div>
    <div class="module-loader" *ngIf="moduleLoading">
      <div class="spinner spinner-bubble spinner-bubble-primary me-3"></div>
    </div>
  </div>
</div>
