import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionQuery } from './session/session.query';
import { SessionService } from './session/session.service';
import { SessionStore } from './session/session.store';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [SessionQuery, SessionService, SessionStore]
})
export class AppStoreModule {}
