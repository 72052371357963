import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { TablePaymentsPatientComponent } from './table-payments-patient.component';

@NgModule({
  declarations: [TablePaymentsPatientComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    SharedPipesModule,
    MatDialogModule
  ],
  exports: [TablePaymentsPatientComponent],
})
export class TablePaymentsPatientModule {}
