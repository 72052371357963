<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>Atendimento Fale com a SI</h1>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <h2>Convênios</h2>
          <app-input-with-tags placeholder="Adicionar convênios" autocompleteItemName="Convênio"
            [searchAutocomplete]="searchPlan" [autocomplete]="healthPlans" [filteredAutocomplete]="filteredPlans"
            [tags]="sendTags('healthPlans')" (onSetTag)="setPlans($event)" (onRemoveTag)="removePlans($event)">
          </app-input-with-tags>
        </div>
        <div class="col-md-6">
          <h2>Especialidades</h2>
          <app-input-with-tags autocompleteItemName="Especialidade" [searchAutocomplete]="searchSpecialty"
            [autocomplete]="specialties" [filteredAutocomplete]="filteredSpecialties"
            placeholder="Adicionar especialidade" [tags]="sendTags('specialties')" (onSetTag)="setSpecialty($event)"
            (onRemoveTag)="removeSpecialty($event)">
          </app-input-with-tags>
        </div>
      </div>
      <div class="row mb-5">
        <form [formGroup]="paymentMap" class="col-md-6 mb-4">
          <h2 class="mb-5">Forma de pagamento</h2>
          <div class="row align-items-center">
            <!-- <div class="col-md-4">
              <mat-checkbox formControlName="debit_card">Cartão de Débito</mat-checkbox>
            </div> -->
            <!-- <div class="col-md-4">
              <mat-checkbox>Transfêrencias</mat-checkbox>
            </div> -->
            <div class="col-md-4">
              <mat-checkbox formControlName="credit_card">Cartão de Crédito</mat-checkbox>
            </div>
            <!-- <div class="col-md-4">
              <mat-checkbox formControlName="cash">Dinheiro</mat-checkbox>
            </div> -->
            <!-- <div class="col-md-4">
              <mat-checkbox formControlName="pix">Pix</mat-checkbox>
            </div> -->
            <!-- <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Outros">
              </mat-form-field>
            </div> -->
          </div>
        </form>
        <form [formGroup]="attendanceMap" class="col-md-6">
          <h2 class="mb-5">Tipo de atendimento</h2>
          <div class="row align-items-center">
            <div class="col-md-4" *ngFor="let item of attendanceMap.controls | keyvalue">
              <mat-checkbox [formControlName]="item.key">{{attendanceToPorguese(item.key)}}</mat-checkbox>
            </div>
          </div>
        </form>
      </div>
      <div class="row mb-3">
        <!-- <div class="col-md-6">
          <h2 class="mb-2">
            Tipo de cobrança
            <i class="fal fa-info-circle ms-2" matTooltipHideDelay="1000" [matTooltip]="infoPaymentType"></i>
          </h2>
          <mat-radio-group formControlName="billingMethod" class="row checkbox-container py-1">
            <mat-radio-button class="col-md-4" value="si">Fale com a Si</mat-radio-button>
            <mat-radio-button class="col-md-4" value="on_site">No local</mat-radio-button>
          </mat-radio-group>
        </div> -->
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput currencyMask formControlName="value" placeholder="Valor da consulta">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form)" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
