import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { CreatePatient, Patient, PatientHistoryPageableResponse, PatientPageableResponse } from '@app-model/patient';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PatientService {
	constructor(private http: HttpClient) {}

	paginate(pageControl: PageControl, idDoctor): Observable<PatientPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}

		return this.http.post<PatientPageableResponse>(`${environment.api}/patient/${idDoctor}/search`, body).pipe(take(1));
	}

	paginateHistory(pageControl: PageControl, filter): Observable<PatientHistoryPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
      ...filter
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}
		return this.http.post<PatientHistoryPageableResponse>(`${environment.api}/patient/history`, body).pipe(take(1));
	}

	paginateHistoryById(pageControl: PageControl, filter, idPatient: string): Observable<PatientHistoryPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
      ...filter
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}

		return this.http
			.post<PatientHistoryPageableResponse>(`${environment.api}/patient/history/${idPatient}`, body)
			.pipe(take(1));
	}

  createLogin(data: CreatePatient): Observable<any> {
    return this.http.post<any>(`${environment.api}/login/createPatient`, data);
  }

  uploadImage(file: File, id: string): Observable<any> {
    const body = new FormData()
    body.append('file', file);

    return this.http.post(`${environment.api}/patient/${id}/image-upload`, body);
  }

	getById(idPatient: string): Observable<any> {
		return this.http.get<any>(`${environment.api}/patient/${idPatient}`).pipe(take(1), map((response: any) => response.data));
	}

	getByEmail(emailPatient: string): Observable<any> {
		return this.http.get<any>(`${environment.api}/patient/by-email/${emailPatient}`);
	}

  update(patient: Patient): Observable<any> {
    return this.http.put<any>(`${environment.api}/patient/${patient.id}`, patient);
  }

  removePatients(doctorId: string, patients: string[]): Observable<any> {
    return this.http.post<any>(`${environment.api}/patient/${doctorId}/delete`, {ids: patients})
  }

  createPatient(patient: Patient): Observable<any> {
    return this.http.post<any>(`${environment.api}/patient`, patient)
  }

  getPaymentCheckouts(pageControl: PageControl, idPatient): Observable<any> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}

		return this.http.post<any>(`${environment.api}/patient/${idPatient}/payment-checkout/search`, body);
	}

  getPaymentCards(pageControl: PageControl, idPatient): Observable<any> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		return this.http.post<any>(`${environment.api}/patient/${idPatient}/payment-card/search`, body);
	}

  createPaymentCards(card, idPatient): Observable<any> {
		return this.http.post<any>(`${environment.api}/patient/${idPatient}/payment-card/create`, card);
	}

  deletePaymentCard(idCard, idPatient): Observable<any> {
		return this.http.delete<any>(`${environment.api}/patient/${idPatient}/payment-card/${idCard}/delete`);
	}
}
