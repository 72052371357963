import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';
import { DialogNewExpertExperienceComponent } from './dialog-new-expert-experience.component';

@NgModule({
  declarations: [
    DialogNewExpertExperienceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    SharedFormsModule
  ]
})
export class DialogNewExpertExperienceModule {}
