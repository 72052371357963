import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Commentary } from '@app-model/commentary';
import { PageControl } from '@app-model/page-control';
import { DoctorService } from '@app-services/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-table-expert-comments',
  templateUrl: './table-expert-comments.component.html',
  styleUrls: ['./table-expert-comments.component.scss']
})
export class TableExpertCommentsComponent implements OnInit {
	@Input() searchTerm: string = '';
	@Input() idDoctor: string = '';
	@Input() loading = false;

	list: Commentary[] = [];

	columns = [
		{
			slug: 'patientName',
			order: true,
			title: 'NOME DO PACIENTE'
		},
		{
			slug: 'commentary',
			order: true,
			title: 'COMENTÁRIO'
		},
		{
			slug: 'score',
			order: true,
			title: 'AVALIAÇÃO'
		},
		{
			slug: '',
			order: false,
			title: 'REMOVER COMENTÁRIO'
		}
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'start',
		order: 'DESC',
		count: 0
	};

  constructor(
    private readonly toastr: ToastrService,
    private readonly doctorService: DoctorService,
		private readonly matDialog: MatDialog,
  ) { }

	ngOnInit(): void {
		this.getResults();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, scheduleType, loading } = changes;
		if (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
			this.onSearch();
		} else if (scheduleType?.currentValue !== scheduleType?.previousValue){
			this.onSearch();
    } else if (loading?.currentValue !== loading?.previousValue) {
			this.onSearch();
    }
	}

	onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.getResults();
	}

	private getResults() {
    this.loading = true;

		this.pageControl.count = 0;
    this.doctorService.doctorSchedulingFeedback(this.pageControl, this.idDoctor)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        res => this.dataHandler(res),
        err => console.error(err)
      )
	}

  private dataHandler(response: any) {
		this.pageControl.count = response.totalElements;
		this.list = response.data;
	}

	private errorHandler() {
		this.list = [];
		this.pageControl.count = 0;
	}

	private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.param = 'description';
		this.pageControl.page = 0;

    if(!this.loading)
		  this.getResults();
	}

	pageEvent($event: any) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.getResults();
	}

  deleteCommentary(idCommentary: any): void {

    const title = 'Remover comentário';
    const message = `Deseja realmente remover o comentário?`;

		const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			data: {title, message},
			closeOnNavigation: true,
			hasBackdrop: true
		});

		dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.loading = true;
        this.doctorService.deleteDoctorSchedulingFeedback(this.idDoctor, idCommentary)
          .pipe(finalize(() => this.loading = false))
          .subscribe(
            res => {
              if(res.success) {
                this.toastr.success(res.message)
              } else {
                this.toastr.error(res.message)
              }
              this.getResults();
            },
            err => console.error(err)
          )
      }
    })
  }

}
