<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row">
      <div class="col-md-12 text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <h1>Cortar imagem</h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <image-cropper style="max-width: 600px; max-height: 600px;" [imageFile]="imageChangedEvent"
        [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit()" [disabled]="!croppedImage"
          class="btn mx-3 btn-confirm">Cortar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
