export enum TypeScheduling {
	COMMUN = 'COMMUN',
	SI = 'SI'
}

export enum StatusScheduling {
	AVAIABLE = "AVAIABLE",
	WAITING = "WAITING",
	CANCELED = "CANCELED",
	UNAVAIABLE = "UNAVAIABLE",
	GHOST = "GHOST"
}

export enum AttendanceType {
  ADULT = 'ADULT',
  PEDIATRIC = 'PEDIATRIC'
}

export enum SchedulingAttendanceType {
	COMMUN = 'COMMUN',
	ONLINE = 'ONLINE',
  HOMECARE = 'HOMECARE'
}
