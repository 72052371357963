import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { Schedule, ScheduleDataDefaultResponse } from '@app-model/schedule';
import { Scheduling, SchedulingDataDefaultResponse } from '@app-model/scheduling';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  scheduleUpdate(data): Observable<any> {
    return this.http.put(`${environment.api}/schedule`, data);
  }

  schedulingSearch(data): Observable<any> {
		return this.http
			.post(`${environment.api}/schedule/${data.scheduleId}/scheduling/search`, data);
	}

	getScheduleById(idSchedule): Observable<Schedule> {
		return this.http.get<ScheduleDataDefaultResponse>(`${environment.api}/schedule/${idSchedule}`).pipe(take(1), map((response: ScheduleDataDefaultResponse) => response.data));
	}

	getSchedulingById(idSchedule, idScheduling): Observable<Scheduling> {
		return this.http.get<SchedulingDataDefaultResponse>(`${environment.api}/schedule/${idSchedule}/scheduling/${idScheduling}`).pipe(take(1), map((response: SchedulingDataDefaultResponse) => response.data));
	}

	saveOrUpdate(scheduling) {
		return this.http
			.post(`${environment.api}/schedule/${scheduling.scheduleId}/scheduling`, scheduling);
	}

	delete(idSchedule, idScheduling) {
		return this.http.delete(`${environment.api}/schedule/${idSchedule}/scheduling/${idScheduling}`).pipe(take(1));
	}

	cancelSchedule(idSchedule, idScheduling) {
		return this.http.delete(`${environment.api}/schedule/${idSchedule}/scheduling/${idScheduling}/cancel`).pipe(take(1));
	}

	getClinics(){
		return this.http.get(`${environment.api}/clinic`).pipe(take(1), map((response: any) => response.data));
	}

	paginateClinics(pageControl: PageControl){
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
			value: pageControl.value
		};
		return this.http.post<any>(`${environment.api}/clinic/search`, body).pipe(take(1));
	}

	getFreeTimeByClinicId(data){
		return this.http.get(
			`${environment.api}/clinic/${data.clinicId}/free-time?start=${data.start}&end=${data.end}`
		).pipe(take(1), map((response: any) => response.data));
	}

	getDoctorsByClinicId(data){
		return this.http.get(
			`${environment.api}/clinic/${data.clinicId}/doctors?start=${data.start}&end=${data.end}`
		).pipe(take(1), map((response: any) => response.data));
	}

  getSchedulingSettings(id: string): Observable<any> {
    return this.http.get(`${environment.api}/schedule/${id}/settings/all`);
  }

  updateSchedulingSettings(id: string, settings): Observable<any> {
    return this.http.post(`${environment.api}/schedule/${id}/settings/upsert`, settings);
  }

  postAbsent(idSchedule, idScheduling): Observable<any> {
		return this.http.post<any>(`${environment.api}/schedule/${idSchedule}/scheduling/${idScheduling}/no-show`, {});
  }
}
