import { AmplifyService } from '@app-services/amplify.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import type { JWT } from 'aws-amplify/auth';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private amplifyService: AmplifyService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.amplifyService.isAuthenticated()).pipe(
			mergeMap(isAuthenticate => {
				if (isAuthenticate) {
					return this.amplifyService.getJwt().pipe(
						mergeMap(jwt => {
							return this.setJwtOrSkip(request, next, jwt);
						}),
						catchError(err => {
							if (err.status === 0) {
								return throwError(err);
							}
							return throwError(err);
						})
					);
				}
				const headers = request.headers.delete(InterceptorSkipHeader);
				request = request.clone({ headers });
				return next.handle(request);
			})
		);
	}

	private setJwtOrSkip(request: HttpRequest<any>, next: HttpHandler, jwt: JWT): Observable<HttpEvent<any>> {
		if (!request.headers.has(InterceptorSkipHeader)) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${jwt.toString()}`,
				}
			});
		} else {
			const headers = request.headers.delete(InterceptorSkipHeader);
			request = request.clone({ headers });
		}
		return next.handle(request);
	}
}
