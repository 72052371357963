import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

@Pipe({
  name: 'nextDate'
})
export class NextDatePipe implements PipeTransform {

  transform(date: Date, ...args: unknown[]): string {
    if(date){
      const dayOfWeek = format(new Date(date), 'ccc', { locale: ptBR }).slice(0, 3);
      const dayAndMonth = format(new Date(date), 'dd MMMM', { locale: ptBR });
      return `${dayOfWeek}, ${dayAndMonth}`;
    }

    return '';
  }

}
