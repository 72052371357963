import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogSearchScheduleComponent } from './dialog-search-schedule.component';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [DialogSearchScheduleComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule
  ]
})
export class DialogSearchScheduleModule { }
