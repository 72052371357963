import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

@Pipe({
  name: 'timeAmPm'
})
export class TimeAmPmPipe implements PipeTransform {

  transform(date: Date, ...args: unknown[]): unknown {
    if(date) {
      const d = new Date(date);
			const hour = format(d, 'h', { locale: ptBR });
			const minute = format(d, 'mm', { locale: ptBR });
			const time = format(d, 'a', { locale: ptBR }).toLowerCase();

			return `${hour}:${minute}${time}`;
		}

		return '';
  }

}
