import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControlStatus } from '@app-shared/enums/form';
import { cpfMask, creditCardMask, dateCreditCard } from '@app-shared/masks';
import { Utils } from '@app-shared/utils';
import { CreditCardValidator, DocumentValidator } from '@app-shared/validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-payment-expert',
  templateUrl: './dialog-payment-expert.component.html',
  styleUrls: ['./dialog-payment-expert.component.scss']
})
export class DialogPaymentExpertComponent implements OnInit {

  form: FormGroup;

  creditCardMask = {
		guide: false,
		mask: creditCardMask
	}

  dateMask = {
		guide: true,
		mask: dateCreditCard
	}

  cpfMask = {
		guide: true,
		mask: cpfMask
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<DialogPaymentExpertComponent>,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      cardNumber: [null, [Validators.required, CreditCardValidator.validateNumber]],
      expirationDate: [null, [Validators.required, CreditCardValidator.validateExpiration]],
      cvv: [null, [Validators.required]],
      name: [null, [Validators.required]],
      cpf: [null, [Validators.required, DocumentValidator.cpf]]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(form: FormGroup): void {
    if(Utils.checkIsValidForm(form)) {

      this.dialogRef.close(form.value);

    } else {

      let message = 'Os seguintes campos são obrigatórios: ';
      Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
        if(value.status === FormControlStatus.INVALID || value.status === FormControlStatus.PENDING){
          if(key === 'cardNumber') message += 'Número do cartão, '
          if(key === 'expirationDate') message += 'Data de validade, '
          if(key === 'cvv') message += 'CVV, '
          if(key === 'name') message += 'Nome do títular, '
          if(key === 'cpf') message += 'CPF do títular, '
        }
      })
      message += 'preencha todos corretamente antes de continuar.'

      this.toastr.warning('', message);
    }

  }

}
