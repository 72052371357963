import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViaCep } from '@app-model/application';
import { ApplicationService } from '@app-services/application.service';
import { OpenFilterService } from '@app-services/open-filter.service';
import { FormControlStatus } from '@app-shared/enums/form';
import { SchedulingAttendanceType } from '@app-shared/enums/schedulings';
import { phoneMask } from '@app-shared/masks';
import { cepMask } from '@app-shared/masks';
import { Utils } from '@app-shared/utils';
import { unwatchFile } from 'fs';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-new-expert-address',
  templateUrl: './dialog-new-expert-address.component.html',
  styleUrls: ['./dialog-new-expert-address.component.scss']
})
export class DialogNewExpertAddressComponent implements OnInit {
	form: FormGroup;
  scheduleTypeMap: FormGroup;

  phoneMask = {
		guide: true,
		mask: phoneMask
  }

  cepMask = {
		guide: true,
		mask: cepMask
  }

	filteredCities = [];

  scheduleTypes = {
    commum: true,
    online: false,
    homecare: false
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly openFilterService: OpenFilterService,
    private readonly applicationService: ApplicationService,
    private readonly dialogRef: MatDialogRef<DialogNewExpertAddressComponent>,
		private readonly toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.scheduleTypeMap = this.fb.group({
      commum: [true, Validators.required],
      online: [false, Validators.required],
      homecare: [false, Validators.required]
    });

    this.form = this.fb.group({
      name: [null, Validators.required],
      cep: [null, Validators.required],
      address: [null, Validators.required],
      number: [null, Validators.required],
      neighborhood: [null, Validators.required],
      complement: [null],
      city: [null, Validators.required],
      cityId: [null, Validators.required],
      uf: [null, Validators.required],
      telephone: [null],
      scheduleTypes: this.scheduleTypeMap
    });

    this.fillForm(this.data);
  }

  private fillForm(data) {
    if(data){
      const { name, address, number, neighborhood, cep, complement, city, cityId, uf, telephone, scheduleTypeMap } = data;
      this.form.patchValue({ name, address, number, neighborhood, cep, complement, city, cityId, uf, telephone })

      this.scheduleTypeMap.patchValue({...scheduleTypeMap});

      this.form.get('address').disable();
      this.form.get('neighborhood').disable();
      this.form.get('city').disable();
      this.form.get('uf').disable();
    }
  }

  clearInput(e, controlName) {
    this.form.get(controlName).setValue(null);
    e.stopPropagation();
  }

  getAddressByCep(value: string): void {
    const cep = Utils.onlyNumbers(value);
    if(cep.length === 8) {
      this.applicationService.getCep(cep)
      .subscribe(
        response => this.getAddressByCepResponse(response),
        error => this.getAddressByCepError(error)
      )
    }
  }

  getAddressByCepResponse(response: ViaCep): void {

    if(response?.erro){
      this.getAddressByCepError(response);
      return;
    }

    const { logradouro, bairro, localidade, uf } = response;

    this.form.patchValue({
      address: logradouro,
      neighborhood: bairro
    })

    this.form.get('address').disable();
    this.form.get('neighborhood').disable();

    this.openFilterService.getCityByNameAndUf(localidade, uf)
    .subscribe(
      response => {
        if(response){
          const { id, name, uf } = response;

          this.form.patchValue({
            city: name,
            cityId: id,
            uf: uf
          })

          this.form.get('city').disable();
          this.form.get('uf').disable();
        }
      },
      error => console.error({error})
    )
  }

  getAddressByCepError(err):void {
    console.error(err);

    this.form.get('address').enable();
    this.form.get('neighborhood').enable();
    this.form.get('city').enable();
    this.form.get('uf').enable();

    this.form.patchValue({
      city: null,
      cityId: null,
      uf: null,
      address: null,
      neighborhood: null,
    })
  }

  getCities(value): void {
    if(value && value.length >= 3){
      this.openFilterService.getCities(value)
        .subscribe(
          response => this.getCitiesResponse(response),
          error => console.error({error})
        )
    }
  }

  citySelected(value: MatOption): void {
    const [city,uf] = value.viewValue.split('/')

    const { id } = this.filteredCities.find(item => item.name === city && item.uf === uf);

    this.form.patchValue({
      cityId: id,
      city,
      uf
    })
  }

  private getCitiesResponse(response): void {
    this.filteredCities = response;
  }

  // setCityId() {
  //   const cityName = this.form.controls['city'].value;
  //   if(this.filteredCities.length){
  //     const city = this.filteredCities.find(filter => filter.name === cityName);

  //     this.form.controls['cityId'].setValue(city.id);
  //   }else{
  //     this.form.controls['cityId'].setValue(cityName);
  //   }
  // }

  onSubmit(form): void {
    if(Utils.checkIsValidForm(form)) {
      if(this._hasScheduleTypeAccept()){
        this.dialogRef.close(this.form.getRawValue());

      }else {
        this.toastr.warning('Você precisa escolher pelo menos um tipo de atendimento');
      }
    } else {

      let message = 'Os seguintes campos são obrigatórios: ';
      Object.entries(form.controls).forEach(([key, value] : [string, FormControl]) => {
        if(value.status === FormControlStatus.INVALID || value.status === FormControlStatus.PENDING){
          if(key === 'name') message += 'Nome do local, '
          if(key === 'cep') message += 'CEP, '
          if(key === 'address') message += 'Endereço, '
          if(key === 'number') message += 'Número, '
          if(key === 'neighborhood') message += 'Bairro, '
          if(key === 'city') message += 'Cidade, '
          if(key === 'uf') message += 'Estado, '
        }
      })
      message += 'preencha todos corretamente antes de continuar.'

      this.toastr.warning('', message);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private _hasScheduleTypeAccept(): boolean {
    let result = false;
    Object.entries(this.scheduleTypeMap.getRawValue())
      .map(([key, value]: [key: string, value: boolean]) => {
        if(value){
          result = value;
        }
      })

    return result;
  }

  getScheduleTypeArray(): string[] {
    const result = []
    Object.entries(this.scheduleTypes).forEach(([key, value]) => {
      if(value){
        result.push(key.toUpperCase())
      }
    })

    return result;
  }
}
