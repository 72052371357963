<div class="select-schedule" role="button" (click)="openDialog()">
  <div class="icon">
    <i class="fal fa-{{type === 'DOC' ? 'user-md' : 'map-marker-alt'}}"></i>
  </div>
  <div class="label">
    {{type === 'DOC' ? 'Especialista' : 'Local'}}
  </div>
  <div class="description">
    <span><strong>{{ name }}</strong> {{ address && '|'}} {{ address }}</span>
  </div>
  <div class="icon-call">
    <i class="fal fa-angle-down"></i>
  </div>
</div>
