<header id="main-header">
  <div class="menu-toggle" (click)="toggelSidebar()">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="logo">
    <a [routerLink]="['/']">
      <img src="./assets/images/logo.svg" height="32px" />
    </a>
  </div>

  <div class="ms-md-auto"></div>

  <div class="header-part-right buttons" *ngIf="(isAuthenticated$ | async) === false">
    <div class="row">
      <div class="col">
        <a [routerLink]="['/sign-up']" routerLinkActive="router-link-active"
          class="btn btn-primary btn-rounded btn-block">CRIAR CONTA</a>
      </div>
      <div class="col">
        <a [routerLink]="['/login']" class="btn btn-outline-secondary btn-rounded btn-block">ENTRAR</a>
      </div>
    </div>
  </div>

  <div class="header-part-right" *ngIf="isAuthenticated$ | async">
    <div ngbDropdown [placement]="'bottom-right'" class="user col">
      <span id="userDropdown" ngbDropdownToggle alt="">
        <i class="fal fa-user-circle header-icon" role="button"></i>
        <span class="name-user">Olá, {{ (userInfo$ | async)?.firstName }}</span>
        <i class="fal fa-angle-down ms-2"></i>
      </span>
      <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
        <a *ngFor="let item of menuItems" [routerLink]="item.link" class="dropdown-item">
          <i class="fal fa-angle-right"></i>
          {{item.title}}
        </a>
      </div>
    </div>
  </div>
</header>
<div *ngIf="incompleteProfile" class="incomplete-profile">
  <span>Seu perfil ainda não está visível,&nbsp;<span class="link" (click)="routeToProfile()">complete seu perfil</span>
  </span>
</div>
